import { RouteRecordRaw } from "vue-router"

function lazyLoad(path:string){
  return () => import(`@/views/${path}/index.vue`)
}

export const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: '/home/index',
  },
  {
    path: "/home",
    component: lazyLoad('home'),
    redirect: '/home/index',
    children:[
      {
        path: "index",
        component: lazyLoad('index'),
        meta:{title:'首页'}
      },
      {
        path: "job",
        component: lazyLoad('job'),
        meta:{title:'职位'}
      },
      {
        path: "about",
        component: lazyLoad('about'),
        meta:{title:'关于'}
      },
      {
        name: 'jobDetail',
        path: "/home/jobDetail",
        component: lazyLoad('jobDetail'),
        meta:{title:'详情'}
      },
      {
        path: "user/job",
        component: lazyLoad('user'),
        meta:{title:'用户职位'}
      },
      {
        path: "user/resume",
        component: lazyLoad('resume'),
        meta:{title:'个人中心'}
      },
      {
        path: "user/jobs/fromChat",
        component: lazyLoad('jobFormChat'),
        meta:{title:'投递记录'}
      },
      {
        path: "user/privacy",
        component: lazyLoad('privacy'),
        meta:{title:'简历设置'}
      },
      {
        path: "corporation",
        component: lazyLoad('corporation'),
        meta:{title:'公司'}
      },
      {
        path: "consult",
        component: lazyLoad('consult'),
        meta:{title:'资讯'}
      },
      {
        path: "companyDetail",
        component: lazyLoad('companyDetail'),
        meta:{title:'公司详情'}
      },
      {
        path: "consultDetail",
        component: lazyLoad('consultDetail'),
        meta:{title:'资讯详情'}
      },
      {
        path: "campus",
        component: lazyLoad('campus'),
        meta:{title:'校园'}
      },
      {
        path: "university",
        component: lazyLoad('university'),
        meta:{title:'高校'}
      },
      {
        path: "map",
        component: lazyLoad('map'),
        meta:{title:'地图'}
      },
      {
        path: "help",
        component: lazyLoad('help')
      }
    ]
  },
  {
    path: "/login",
    component: lazyLoad('login'),
    meta:{title:'用户登录'}
  },
  {
    path: "/company",
    component: lazyLoad('company'),
    meta: { title: '企业管理' },
    redirect: '/company/positionManage',
    children: [
      // {
      //   path: '/company/statistics',
      //   component: () => import('@/views/company/views/statistics/index.vue'),
      //   meta:{title:'数据可视化'}
      // },
      {
        path: '/company/positionManage',
        component: () => import('@/views/company/views/positionManage/index.vue'),
        meta:{title:'职位管理'}
      },
      {
        path: '/company/positionManage/edit',
        component: () => import('@/views/company/views/positionManage/edit.vue'),
        meta:{title:'发布职位', back: true}
      },
      {
        path: '/company/recommend',
        component: () => import('@/views/company/views/recommend/index.vue'),
        meta:{title:'推荐牛人'}
      },
      {
        path: '/company/resumeReview',
        component: () => import('@/views/company/views/resumeReview/index.vue'),
        meta:{title:'简历审核'}
      },
      {
        path: '/company/user',
        component: () => import('@/views/company/views/user/index.vue'),
        meta:{title:'牛人管理'}
      },
      {
        path: '/company/companyInfo',
        component: () => import('@/views/company/views/companyInfo/index.vue'),
        meta:{title:'公司信息'}
      },
      {
        path: '/company/hrs',
        component: () => import('@/views/company/views/hrs/index.vue'),
        meta:{title:'公司人事'}
      },
      {
        path: '/company/profile',
        component: () => import('@/views/company/views/profile/index.vue'),
        meta:{title:'个人中心'}
      },
      {
        path: '/company/campus',
        component: () => import('@/views/company/views/campus/index.vue'),
        meta:{title:'校园资源'}
      }
    ]
  },
  {
    path: "/auth",
    component: () => import('@/views/company/views/auth/index.vue'),
    meta: { title: '完善资料' },
    redirect: '/auth/personal',
    children: [
      {
        path: '/auth/personal',
        component: () => import('@/views/company/views/auth/personal/index.vue'),
        meta:{title:'个人认证'}
      },
      {
        path: '/auth/join',
        component: () => import('@/views/company/views/auth/join/index.vue'),
        meta:{title:'公司认证'}
      },
      {
        path: '/auth/company',
        component: () => import('@/views/company/views/auth/company/index.vue'),
        meta:{title:'公司认证'}
      },
      {
        path: '/auth/certification',
        component: () => import('@/views/company/views/auth/certification/index.vue'),
        meta:{title:'公司认证'}
      },
      {
        path: '/auth/success',
        component: () => import('@/views/company/views/auth/success/index.vue'),
        meta:{title:'平台审核'}
      }
    ]
  },
  {
    path: "/notfound",
    component: lazyLoad('notfound'),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/notfound',
    meta:{title:'404'}
  },
  {
    path: "/h5",
    component: lazyLoad('h5'),
    meta:{title:'云就业平台'}
  },
]
