import {defineStore} from "pinia"
import {getConfig} from "../../api"
import { message } from "ant-design-vue"
export const useConfig = defineStore('config',{
  state:()=>{
    return {
      configs: null
    }
  },
  actions:{
    async getConfigs(){
      const res:any  = await getConfig()
      if(res.code==200) {
        this.configs = res.data.config
      }
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
      },
    ],
  }
})