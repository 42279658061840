import { createRouter, createWebHistory } from "vue-router"
import { routes } from "./routes"
import { message } from "ant-design-vue"
import { IHrData } from "@/types/hr";
import { useHrInfo } from "@/store/hr";
const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {// 初始化时进行一次判断
  if (window.innerWidth < 768){
    if (to.path?.split('/')[1]!=='company' && to.path?.split('/')[1]!=='h5') {
      return next('/h5')
    }
  } else {
    if (to.path?.split('/')[1]==='h5') {
      return next('/')
    }
  }
  const token = localStorage.getItem('token')
  const companyToken = localStorage.getItem('companyToken')
  
  if (token && (to.path.includes('/login') && !to.fullPath.includes('boss') )) {
    return next('/')
  }  
  if (companyToken && (to.fullPath=='/login?query=boss' || to.path?.split('/')[1]==='company') ) {
    const hrInfoList = computed(() => useHrInfo().hrInfoList as IHrData);
    let path = ''
  
    if(hrInfoList.value.status !== "1") {
      switch(hrInfoList.value.status) {
        case "0":
          path = '/auth/personal';
          break;
        case "2":
          path = '/auth/join';
          break;
        case "3":
          path = '/auth/company';
          break;
        case "4":
          path = '/auth/certification';
          break;
        default:
          path = '/auth/success';
      }
      return next(path)
    }
    return next()
  }
  if(!token && to.fullPath.includes('/home/user')){
     message.warning("请登录后进行访问")
    return next('/login')
  }
  if(!companyToken && to.path?.split('/')[1]==='company'){
     message.warning("请登录后进行访问")
    return next('/login?query=boss')
  }
  
  next()
  window.scroll({
    top: 0,
    behavior: "auto"
  })
})
router.afterEach((to,from)=>{
  if(to.meta.title){
    document.title = to.meta.title as string
  }
})
export default router
