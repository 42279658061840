import request from '@/api/request'
// 用户登陆
export const userLogin = (data: { phone: string, code: string }) => {
  return request({
    url: '/user/login',
    method: 'post',
    data: data
  })
}

//用户发送验证码
export const userSendCode = (phone: string) => {
  return request({
    url: '/code/send',
    method: 'post',
    data: {
      phone
    }
  })
}

//获取用户信息
export const getUser = () => {
  return request({
    url: '/user/show',
    method: 'post'
  })
}

//修改用户信息
export const updateUser = (data: any) => {
  return request({
    url: '/user',
    method: 'patch',
    data
  })
}

//获取用户简历
export const getUserResume = () => {
  return request({
    url: '/resume/index',
    method: 'get'
  })
}

//添加用户简历
export const addUserResume = (data: any) => {
  return request({
    url: '/user/add/resume',
    method: 'post',
    data
  })
}
//修改用户简历
export const updateUserResume = (data: any) => {
  return request({
    url: '/user/resume',
    method: 'patch',
    data
  })
}

//修改用户简历基本信息
export const updateResumeBaseInfo = (data: any) => {
  return request({
    url: '/resume/update',
    method: 'post',
    data
  })
}

//添加期望岗位
export const addResumeExp = (data: any) => {
  return request({
    url: '/resume/addexp',
    method: 'post',
    data
  })
}

//修改期望岗位
export const updateResumeExp = (data: any) => {
  return request({
    url: '/resume/updateexp',
    method: 'post',
    data
  })
}

//删除期望岗位
export const delResumeExp = (data: any) => {
  return request({
    url: '/resume/delexp',
    method: 'post',
    data
  })
}

//添加工作经历
export const addResumeJob = (data: any) => {
  return request({
    url: '/resume/addjob',
    method: 'post',
    data
  })
}

//修改工作经历
export const updateResumeJob = (data: any) => {
  return request({
    url: '/resume/updatejob',
    method: 'post',
    data
  })
}

//删除工作经历
export const delResumeJob = (data: any) => {
  return request({
    url: '/resume/deljob',
    method: 'post',
    data
  })
}

//添加项目经历
export const addResumeProject = (data: any) => {
  return request({
    url: '/resume/addpro',
    method: 'post',
    data
  })
}

//修改项目经历
export const updateResumeProject = (data: any) => {
  return request({
    url: '/resume/updatepro',
    method: 'post',
    data
  })
}

//删除项目经历
export const delResumeProject = (data: any) => {
  return request({
    url: '/resume/delpro',
    method: 'post',
    data
  })
}

//添加教育经历
export const addResumeEducation = (data: any) => {
  return request({
    url: '/resume/addedu',
    method: 'post',
    data
  })
}

//修改教育经历
export const updateResumeEducation = (data: any) => {
  return request({
    url: '/resume/updateedu',
    method: 'post',
    data
  })
}

//删除教育经历
export const delResumeEducation = (data: any) => {
  return request({
    url: '/resume/deledu',
    method: 'post',
    data
  })
}

//添加资格证书
export const addResumeCertificate = (data: any) => {
  return request({
    url: '/resume/addcert',
    method: 'post',
    data
  })
}

// 企业登陆
export const companyLoginReq = (data: Record<string, string>) => {
  return request({
    url: '/companyLogin',
    method: 'post',
    data: data
  })
}


//获取职位类型
export const getPositionType = () => {
  return request({
    url: '/positionType',
    method: 'get'
  })
}

//查询职位列表
export const getPositionList = (data: any) => {
  return request({
    url: '/positions',
    method: 'get',
    params: data
  })
}
//查询首页搜索框热搜职位列表
export const getPositionHotSearchList = (cityName: string) => {
  return request({
    url: `/positions/hot?cityName=${cityName}`,
    method: 'get'
  })
}

//查询首页热招职位列表
export const getPositionByHot = (position_type1: string) => {
  return request({
    url: `/positions/hotSearch?position_type1=${position_type1}`,
    method: 'get'
  })
}
// 获取职位详情
export const getJobDetail = (position_id: string) => {
  return request({
    url: `/positions/detail`,
    method: 'get',
    params: {
      position_id: position_id
    }
  })
}

//随机排序查询职位列表  /position/by/rand?cityName=天津&num=6&isRandom=true
export const getSortOrRandomPosition = (data: { cityName: string, num?: number, isRandom?: boolean }) => {
  return request({
    url: `/positions/rand`,
    method: 'get',
    params: data
  })
}

// hr登陆注册
export const hrLoginOrRegister = (data: { phone: string, code: string }) => {
  return request({
    url: '/company/login',
    method: 'post',
    data: data
  })
}

// 企业注册 - 个人认证
export const hrIdentify = (data: Record<string, string>) => {
  return request({
    url: '/hr/identify',
    method: 'post',
    data: data
  })
}

// 企业注册 - 加入公司
export const hrJoinCompany = (data: Record<string, string>) => {
  return request({
    url: '/hr/joincompany',
    method: 'post',
    data: data
  })
}

// 企业注册 - 完善公司资料
export const hrImprovecompany = (data: Record<string, string>) => {
  return request({
    url: '/hr/improvecompany',
    method: 'post',
    data: data
  })
}

// 企业注册 - 上传认证资质
export const hrimproveLicense = (data: Record<string, string>) => {
  return request({
    url: '/hr/improveLicense',
    method: 'post',
    data: data
  })
}

// 企业端职位管理
export const hrPositionManage = (company_id: string, pageIndex: number, pageSize: number) => {
  return request({
    url: '/hr/positionManage',
    method: 'get',
    params: {
      company_id: company_id,
      pageIndex: pageIndex,
      pageSize: pageSize
    }
  })
}

// 企业端查看职位详情
export const hrPositionDetail = (positionId: string) => {
  return request({
    url: '/position/hrdetail',
    method: 'get',
    params: {
      positionId
    }
  })
}

// hr发布职位
export const hrAddPosition = (data: Record<string, any>) => {
  return request({
    url: '/position/add',
    method: 'post',
    data
  })
}
// hr删除职位
export const hrDelPosition = (positionId: string) => {
  return request({
    url: '/position/delete',
    method: 'post',
    data: {
      positionId
    }
  })
}

// hr修改职位
export const hrEditPosition = (position_id: string) => {
  return request({
    url: '/hr/editPosition',
    method: 'get',
    params: {
      position_id: position_id
    }
  })
}

// hr修改职位
export const hrEditPosition2 = (data: Record<string, any>) => {
  return request({
    url: '/position/update',
    method: 'post',
    data: data
  })
}

// hr绑定公司
export const hrAddCompany = (data: Record<string, any>) => {
  return request({
    url: '/hr/add/company',
    method: 'post',
    data: data
  })
}
// hr退出
export const hrLogout = () => {
  return request({
    url: '/company/logout',
    method: 'post'
  })
}

// hr推荐牛人列表
export const getResumeRecommendList = (data:any)=>{
  return request({
    url:`/resume/recommendlist`,
    method: 'get',
    data
  })
}


// hr获取牛人详情
export const getResumeDetail = (params:any)=>{
  return request({
    url:`/resume/detail`,
    method: 'get',
    params
  })
}

// hr牛人列表
export const getResumeList = (params:any)=>{
  return request({
    url:`/resume/lists`,
    method: 'get',
    params
  })
}
//-------------投递相关
//添加投递记录
export const addSend = (data: Record<string, any>) => {
  return request({
    url: '/send/add',
    method: 'post',
    data
  })
}
//查询用户投递记录
export const selectSend = (data: any) => {
  return request({
    url: '/send/lists',
    method: 'get',
    params: data
  })
}
//取消/删除用户投递
export const cancelOrDelSend = (data: any) => {
  return request({
    url: '/send/delete',
    method: 'post',
    data
  })
}

//-------------收藏相关
//添加收藏
export const addCollect = (data: Record<string, any>) => {
  return request({
    url: 'collect',
    method: 'post',
    data
  })
}
//查询用户收藏
export const selectCollect = (data: any) => {
  return request({
    url: 'collects',
    method: 'get',
    params: data
  })
}
//删除用户收藏
export const deleteCollect = (data: any) => {
  return request({
    url: 'collect',
    method: 'delete',
    data
  })
}


//获取用户的收藏和投递状态
export const collectOrSendState = (data: any) => {
  return request({
    url: '/positions/state',
    method: 'get',
    params: data
  })
}
//查询用户所有投递状态(数量)
export const allSendNum = () => {
  return request({
    url: '/send/getNumber',
    method: 'get'
  })
}

//-------公司相关
//查询所有公司
export const selectCompanys = (params: Record<string, any>) => {
  return request({
    url: '/companies',
    method: 'get',
    params
  })
}
//查询公司所有职位
export const selectCompanysPositions = (params: Record<string, any>) => {
  return request({
    url: '/companies/positions',
    method: 'get',
    params
  })
}
//查询公司所有职位以及公司信息及公司详情
export const selectCompanysDetail = (company_id: string) => {
  return request({
    url: '/companies/detail',
    method: 'get',
    params: { company_id }
  })
}
//查询公司开启职位
export const getCompanysEnablePositions = () => {
  return request({
    url: '/position/enablelist',
    method: 'get'
  })
}
// 获取投递继立列表
export const deliveryRecord = (params:any) => {
  return request({
    url: '/send/deliveryRecord',
    method: 'get',
    params
  })
}

// 更改投递状态
export const updateDeliveryState = (sendId: string, type: string) => {
  return request({
    url: '/send/updateDeliveryState',
    method: 'get',
    params: {
      sendId,
      type
    }
  })
}


// 更改牛人管理 - 约面
export const updateResumeInterview = (sendId: string) => {
  return request({
    url: '/send/interview',
    method: 'post',
    data: {
      sendId
    }
  })
}


// 更改牛人管理 - 不合适
export const updateResumeReject = (sendId: string) => {
  return request({
    url: '/send/reject',
    method: 'post',
    data: {
      sendId
    }
  })
}

// hr查看在线简历
export const onlineResume = (userId: string) => {
  return request({
    url: '/send/onlineResume',
    method: 'get',
    params: {
      userId
    }
  })
}

//-----------资讯相关
//查询资讯
export const selectConsult = (data:any) => {
  return request({
    url: '/article',
    method: 'get',
    params:data
  })
}
//查询资讯分类
export const selectConsultCategory = () => {
  return request({
    url: '/article/category',
    method: 'get',
  })
}
//查询指定资讯
export const selectConsultOne = (id:string) => {
  return request({
    url: '/article/detail',
    method: 'get',
    params:{id}
  })
}
//查询指定资讯
export const getHotArticle = () => {
  return request({
    url: '/article/hot',
    method: 'get'
  })
}

//---------轮播
export const selectSwiper = () => {
  return request({
    url: '/broadcast',
    method: 'get',
  })
}
//---------行业
export const selectIndustry = () => {
  return request({
    url: '/industry',
    method: 'get',
  })
}

//获取首页热门企业
export const getHotCompany = () => {
  return request({
    url: '/companies/hot',
    method: 'get'
  })
}

//获取提升竞争力
export const getRecommend = () => {
  return request({
    url: '/positions/recommend',
    method: 'get'
  })
}

export const userLogout = () => {
  return request({
    url: '/user/logout',
    method: 'post'
  })
}

//---------------企业端()
//通过人事id获取公司信息
export const selectCompanyInfo = (company_id:string) => {
  return request({
    url: '/company/show',
    method: 'get',
    params:{company_id}
  })
}
//通过人事信息
export const selectHrInfo = () => {
  return request({
    url: '/company/hrshow',
    method: 'get'
  })
}

//查询所有公司的相册
export const selCompanyPhoto = (company_id:string) => {
  return request({
    url: '/company/photos',
    method: 'get',
    params:{company_id}
  })
}
//修改公司信息
export const updateCompanyInfo = (data:any) => {
  return request({
    url: '/company/update',
    method: 'post',
    data
  })
}

//获取人事状态
export const selHrState = (hr_id:string) => {
  return request({
    url: '/hr/state',
    method: 'get',
    params:{hr_id}
  })
}
//获取公司状态
export const selCompanyState = (company_id:string) => {
  return request({
    url: '/company/state',
    method: 'get',
    params:{company_id}
  })
}
//修改人事
export const updateHr = (data:any) => {
  return request({
    url: '/hr/update',
    method: 'post',
    data
  })
}

//查询所有公司
export const getAllCompany = () => {
  return request({
    url: '/companys/all',
    method: 'get',
  })
}

//hr选择绑定公司
export const setHrCompany = (data:any) => {
  return request({
    url: '/hr/build/company',
    method: 'post',
    data
  })
}


//查询关于内容
export const getAbout = ()=>{
  return request({
    url:"/about/content",
    method: 'get',
  })
}

//--------------------数据统计
//查询关于内容
export const getStatistics = (data:{company_id:string,hr_id:string})=>{
  return request({
    url:`/statistics`,
    method: 'get',
    params:data
  })
}

//---------------企业端职位查询
// hr发布的职位列表
export const getCompanyPositions = (params:any)=>{
  return request({
    url:`/position/hrlist`,
    method: 'get',
    params
  })
}

//修改职位状态(企业端)
export const updatePositionState = (data:any)=>{
  return request({
    url:`/position/state`,
    method: 'post',
    data
  })
}

//修改职位状态(企业端)
export const openPosition = (data:any)=>{
  return request({
    url:`/position/open`,
    method: 'post',
    data
  })
}

//修改职位状态(企业端)
export const closePosition = (data:any)=>{
  return request({
    url:`/position/close`,
    method: 'post',
    data
  })
}

//查询公司人事(企业端)
export const selectCompanyHrs = (data:any)=>{
  return request({
    url:`/hr/lists`,
    method: 'post',
    data
  })
}

// 获取校园资源
export const getCampusList = (params:any) => {
  return request({
    url:`/university/lists`,
    method: 'get',
    params
  })
}

// 获取高校列表
export const getUniversities = (universityName: string) => {
  return request({
    url:`/university/lists`,
    method: 'get',
    params: {universityName}
  })
}

// 获取省市区列表
export const getProvinces = () => {
  return request({
    url:`/province/index`,
    method: 'get'
  })
}

// 获取校园首页
export const getCampus = () => {
  return request({
    url:`/home/school`,
    method: 'get'
  })
}

// 获取热门高校
export const getUniversityHot = () => {
  return request({
    url:`/university/hot`,
    method: 'get'
  })
}

// 获取配置信息
export const getConfig = () => {
  return request({
    url:`/home/config`,
    method: 'get'
  })
}



