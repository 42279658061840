export const cityList  = [
  {
      "code": 101010000,
      "name": "北京",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101010100,
              "name": "北京",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 110101,
                      "name": "东城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110102,
                      "name": "西城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110105,
                      "name": "朝阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110106,
                      "name": "丰台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110107,
                      "name": "石景山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110108,
                      "name": "海淀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110109,
                      "name": "门头沟区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110111,
                      "name": "房山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110112,
                      "name": "通州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110113,
                      "name": "顺义区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110114,
                      "name": "昌平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110115,
                      "name": "大兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110116,
                      "name": "怀柔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110117,
                      "name": "平谷区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110118,
                      "name": "密云区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 110119,
                      "name": "延庆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "beijing",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "b",
      "pinyin": null,
      "rank": 1,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101020000,
      "name": "上海",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101020100,
              "name": "上海",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 310101,
                      "name": "黄浦区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310104,
                      "name": "徐汇区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310105,
                      "name": "长宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310106,
                      "name": "静安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310107,
                      "name": "普陀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310109,
                      "name": "虹口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310110,
                      "name": "杨浦区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310112,
                      "name": "闵行区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310113,
                      "name": "宝山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310114,
                      "name": "嘉定区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310115,
                      "name": "浦东新区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310116,
                      "name": "金山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310117,
                      "name": "松江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310118,
                      "name": "青浦区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310120,
                      "name": "奉贤区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 310151,
                      "name": "崇明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shanghai",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 9,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101030000,
      "name": "天津",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101030100,
              "name": "天津",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 120101,
                      "name": "和平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120102,
                      "name": "河东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120103,
                      "name": "河西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120104,
                      "name": "南开区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120105,
                      "name": "河北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120106,
                      "name": "红桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120110,
                      "name": "东丽区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120111,
                      "name": "西青区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120112,
                      "name": "津南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120113,
                      "name": "北辰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120114,
                      "name": "武清区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120115,
                      "name": "宝坻区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120116,
                      "name": "滨海新区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120117,
                      "name": "宁河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120118,
                      "name": "静海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 120119,
                      "name": "蓟州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tianjin",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "t",
      "pinyin": null,
      "rank": 2,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101040000,
      "name": "重庆",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101040100,
              "name": "重庆",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 500101,
                      "name": "万州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500102,
                      "name": "涪陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500103,
                      "name": "渝中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500104,
                      "name": "大渡口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500105,
                      "name": "江北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500106,
                      "name": "沙坪坝区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500107,
                      "name": "九龙坡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500108,
                      "name": "南岸区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500109,
                      "name": "北碚区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500110,
                      "name": "綦江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500111,
                      "name": "大足区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500112,
                      "name": "渝北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500113,
                      "name": "巴南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500114,
                      "name": "黔江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500115,
                      "name": "长寿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500116,
                      "name": "江津区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500117,
                      "name": "合川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500118,
                      "name": "永川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500119,
                      "name": "南川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 19,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500120,
                      "name": "璧山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 20,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500151,
                      "name": "铜梁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 21,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500152,
                      "name": "潼南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 22,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500153,
                      "name": "荣昌区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 23,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500154,
                      "name": "开州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500155,
                      "name": "梁平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500156,
                      "name": "武隆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500229,
                      "name": "城口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500230,
                      "name": "丰都县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500231,
                      "name": "垫江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500233,
                      "name": "忠县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500235,
                      "name": "云阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500236,
                      "name": "奉节县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500237,
                      "name": "巫山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500238,
                      "name": "巫溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500240,
                      "name": "石柱土家族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500241,
                      "name": "秀山土家族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500242,
                      "name": "酉阳土家族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 500243,
                      "name": "彭水苗族土家族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chongqing",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "c",
      "pinyin": null,
      "rank": 22,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101050000,
      "name": "黑龙江",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101050100,
              "name": "哈尔滨",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230102,
                      "name": "道里区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230103,
                      "name": "南岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230104,
                      "name": "道外区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230108,
                      "name": "平房区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230109,
                      "name": "松北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230110,
                      "name": "香坊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230111,
                      "name": "呼兰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230112,
                      "name": "阿城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230113,
                      "name": "双城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230123,
                      "name": "依兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230124,
                      "name": "方正县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230125,
                      "name": "宾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230126,
                      "name": "巴彦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230127,
                      "name": "木兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230128,
                      "name": "通河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230129,
                      "name": "延寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230183,
                      "name": "尚志市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230184,
                      "name": "五常市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "haerbin",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050200,
              "name": "齐齐哈尔",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230202,
                      "name": "龙沙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230203,
                      "name": "建华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230204,
                      "name": "铁锋区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230205,
                      "name": "昂昂溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230206,
                      "name": "富拉尔基区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230207,
                      "name": "碾子山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230208,
                      "name": "梅里斯达斡尔族区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230221,
                      "name": "龙江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230223,
                      "name": "依安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230224,
                      "name": "泰来县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230225,
                      "name": "甘南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230227,
                      "name": "富裕县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230229,
                      "name": "克山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230230,
                      "name": "克东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230231,
                      "name": "拜泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230281,
                      "name": "讷河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qiqihaer",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050300,
              "name": "牡丹江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 231002,
                      "name": "东安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231003,
                      "name": "阳明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231004,
                      "name": "爱民区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231005,
                      "name": "西安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231025,
                      "name": "林口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231081,
                      "name": "绥芬河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231083,
                      "name": "海林市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231084,
                      "name": "宁安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231085,
                      "name": "穆棱市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231086,
                      "name": "东宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "mudanjiang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050400,
              "name": "佳木斯",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230803,
                      "name": "向阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230804,
                      "name": "前进区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230805,
                      "name": "东风区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230811,
                      "name": "郊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230822,
                      "name": "桦南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230826,
                      "name": "桦川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230828,
                      "name": "汤原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230881,
                      "name": "同江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230882,
                      "name": "富锦市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230883,
                      "name": "抚远市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiamusi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050500,
              "name": "绥化",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 231202,
                      "name": "北林区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231221,
                      "name": "望奎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231222,
                      "name": "兰西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231223,
                      "name": "青冈县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231224,
                      "name": "庆安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231225,
                      "name": "明水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231226,
                      "name": "绥棱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231281,
                      "name": "安达市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231282,
                      "name": "肇东市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231283,
                      "name": "海伦市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suihua",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050600,
              "name": "黑河",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 231102,
                      "name": "爱辉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231123,
                      "name": "逊克县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231124,
                      "name": "孙吴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231181,
                      "name": "北安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231182,
                      "name": "五大连池市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 231183,
                      "name": "嫩江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "heihe",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050700,
              "name": "伊春",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230717,
                      "name": "伊美区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230718,
                      "name": "乌翠区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230719,
                      "name": "友好区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230722,
                      "name": "嘉荫县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230723,
                      "name": "汤旺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230724,
                      "name": "丰林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230725,
                      "name": "大箐山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230726,
                      "name": "南岔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230751,
                      "name": "金林区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230781,
                      "name": "铁力市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yichun",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050800,
              "name": "大庆",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230602,
                      "name": "萨尔图区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230603,
                      "name": "龙凤区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230604,
                      "name": "让胡路区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230605,
                      "name": "红岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230606,
                      "name": "大同区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230621,
                      "name": "肇州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230622,
                      "name": "肇源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230623,
                      "name": "林甸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230624,
                      "name": "杜尔伯特蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "daqing",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050900,
              "name": "七台河",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230902,
                      "name": "新兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230903,
                      "name": "桃山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230904,
                      "name": "茄子河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230921,
                      "name": "勃利县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qitaihe",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051000,
              "name": "鸡西",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230302,
                      "name": "鸡冠区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230303,
                      "name": "恒山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230304,
                      "name": "滴道区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230305,
                      "name": "梨树区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230306,
                      "name": "城子河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230307,
                      "name": "麻山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230321,
                      "name": "鸡东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230381,
                      "name": "虎林市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230382,
                      "name": "密山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jixi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051100,
              "name": "鹤岗",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230402,
                      "name": "向阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230403,
                      "name": "工农区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230404,
                      "name": "南山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230405,
                      "name": "兴安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230406,
                      "name": "东山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230407,
                      "name": "兴山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230421,
                      "name": "萝北县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230422,
                      "name": "绥滨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hegang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051200,
              "name": "双鸭山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 230502,
                      "name": "尖山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230503,
                      "name": "岭东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230505,
                      "name": "四方台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230506,
                      "name": "宝山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230521,
                      "name": "集贤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230522,
                      "name": "友谊县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230523,
                      "name": "宝清县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 230524,
                      "name": "饶河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shuangyashan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051300,
              "name": "大兴安岭地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 232701,
                      "name": "漠河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 232721,
                      "name": "呼玛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 232722,
                      "name": "塔河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "daxinganling",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 8,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101060000,
      "name": "吉林",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101060100,
              "name": "长春",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220102,
                      "name": "南关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220103,
                      "name": "宽城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220104,
                      "name": "朝阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220105,
                      "name": "二道区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220106,
                      "name": "绿园区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220112,
                      "name": "双阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220113,
                      "name": "九台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220122,
                      "name": "农安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220182,
                      "name": "榆树市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220183,
                      "name": "德惠市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220381,
                      "name": "公主岭市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changchun",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060200,
              "name": "吉林",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220202,
                      "name": "昌邑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220203,
                      "name": "龙潭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220204,
                      "name": "船营区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220211,
                      "name": "丰满区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220221,
                      "name": "永吉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220281,
                      "name": "蛟河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220282,
                      "name": "桦甸市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220283,
                      "name": "舒兰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220284,
                      "name": "磐石市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jilin",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060300,
              "name": "四平",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220302,
                      "name": "铁西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220303,
                      "name": "铁东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220322,
                      "name": "梨树县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220323,
                      "name": "伊通满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220382,
                      "name": "双辽市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "siping",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060400,
              "name": "通化",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220502,
                      "name": "东昌区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220503,
                      "name": "二道江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220521,
                      "name": "通化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220523,
                      "name": "辉南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220524,
                      "name": "柳河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220581,
                      "name": "梅河口市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220582,
                      "name": "集安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tonghua",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060500,
              "name": "白城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220802,
                      "name": "洮北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220821,
                      "name": "镇赉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220822,
                      "name": "通榆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220881,
                      "name": "洮南市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220882,
                      "name": "大安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baicheng",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060600,
              "name": "辽源",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220402,
                      "name": "龙山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220403,
                      "name": "西安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220421,
                      "name": "东丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220422,
                      "name": "东辽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liaoyuan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060700,
              "name": "松原",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220702,
                      "name": "宁江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220721,
                      "name": "前郭尔罗斯蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220722,
                      "name": "长岭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220723,
                      "name": "乾安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220781,
                      "name": "扶余市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "songyuan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060800,
              "name": "白山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 220602,
                      "name": "浑江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220605,
                      "name": "江源区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220621,
                      "name": "抚松县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220622,
                      "name": "靖宇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220623,
                      "name": "长白朝鲜族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 220681,
                      "name": "临江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baishan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060900,
              "name": "延边朝鲜族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 222401,
                      "name": "延吉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222402,
                      "name": "图们市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222403,
                      "name": "敦化市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222404,
                      "name": "珲春市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222405,
                      "name": "龙井市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222406,
                      "name": "和龙市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222424,
                      "name": "汪清县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 222426,
                      "name": "安图县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yanbian",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 7,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101070000,
      "name": "辽宁",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101070100,
              "name": "沈阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210102,
                      "name": "和平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210103,
                      "name": "沈河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210104,
                      "name": "大东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210105,
                      "name": "皇姑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210106,
                      "name": "铁西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210111,
                      "name": "苏家屯区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210112,
                      "name": "浑南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210113,
                      "name": "沈北新区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210114,
                      "name": "于洪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210115,
                      "name": "辽中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210123,
                      "name": "康平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210124,
                      "name": "法库县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210181,
                      "name": "新民市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shenyang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070200,
              "name": "大连",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210202,
                      "name": "中山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210203,
                      "name": "西岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210204,
                      "name": "沙河口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210211,
                      "name": "甘井子区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210212,
                      "name": "旅顺口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210213,
                      "name": "金州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210214,
                      "name": "普兰店区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210224,
                      "name": "长海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210281,
                      "name": "瓦房店市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210283,
                      "name": "庄河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dalian",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070300,
              "name": "鞍山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210302,
                      "name": "铁东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210303,
                      "name": "铁西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210304,
                      "name": "立山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210311,
                      "name": "千山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210321,
                      "name": "台安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210323,
                      "name": "岫岩满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210381,
                      "name": "海城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "anshan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070400,
              "name": "抚顺",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210402,
                      "name": "新抚区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210403,
                      "name": "东洲区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210404,
                      "name": "望花区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210411,
                      "name": "顺城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210421,
                      "name": "抚顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210422,
                      "name": "新宾满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210423,
                      "name": "清原满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fushun",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070500,
              "name": "本溪",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210502,
                      "name": "平山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210503,
                      "name": "溪湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210504,
                      "name": "明山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210505,
                      "name": "南芬区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210521,
                      "name": "本溪满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210522,
                      "name": "桓仁满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "benxi",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070600,
              "name": "丹东",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210602,
                      "name": "元宝区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210603,
                      "name": "振兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210604,
                      "name": "振安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210624,
                      "name": "宽甸满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210681,
                      "name": "东港市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210682,
                      "name": "凤城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dandong",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070700,
              "name": "锦州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210702,
                      "name": "古塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210703,
                      "name": "凌河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210711,
                      "name": "太和区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210726,
                      "name": "黑山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210727,
                      "name": "义县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210781,
                      "name": "凌海市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210782,
                      "name": "北镇市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jinzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070800,
              "name": "营口",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210802,
                      "name": "站前区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210803,
                      "name": "西市区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210804,
                      "name": "鲅鱼圈区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210811,
                      "name": "老边区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210881,
                      "name": "盖州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210882,
                      "name": "大石桥市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yingkou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070900,
              "name": "阜新",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 210902,
                      "name": "海州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210903,
                      "name": "新邱区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210904,
                      "name": "太平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210905,
                      "name": "清河门区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210911,
                      "name": "细河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210921,
                      "name": "阜新蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 210922,
                      "name": "彰武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fuxin",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071000,
              "name": "辽阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 211002,
                      "name": "白塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211003,
                      "name": "文圣区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211004,
                      "name": "宏伟区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211005,
                      "name": "弓长岭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211011,
                      "name": "太子河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211021,
                      "name": "辽阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211081,
                      "name": "灯塔市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liaoyang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071100,
              "name": "铁岭",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 211202,
                      "name": "银州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211204,
                      "name": "清河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211221,
                      "name": "铁岭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211223,
                      "name": "西丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211224,
                      "name": "昌图县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211281,
                      "name": "调兵山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211282,
                      "name": "开原市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tieling",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071200,
              "name": "朝阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 211302,
                      "name": "双塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211303,
                      "name": "龙城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211321,
                      "name": "朝阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211322,
                      "name": "建平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211324,
                      "name": "喀喇沁左翼蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211381,
                      "name": "北票市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211382,
                      "name": "凌源市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chaoyang",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071300,
              "name": "盘锦",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 211102,
                      "name": "双台子区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211103,
                      "name": "兴隆台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211104,
                      "name": "大洼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211122,
                      "name": "盘山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "panjin",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071400,
              "name": "葫芦岛",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 211402,
                      "name": "连山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211403,
                      "name": "龙港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211404,
                      "name": "南票区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211421,
                      "name": "绥中县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211422,
                      "name": "建昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 211481,
                      "name": "兴城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huludao",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "l",
      "pinyin": null,
      "rank": 6,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101080000,
      "name": "内蒙古",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101080100,
              "name": "呼和浩特",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150102,
                      "name": "新城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150103,
                      "name": "回民区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150104,
                      "name": "玉泉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150105,
                      "name": "赛罕区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150121,
                      "name": "土默特左旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150122,
                      "name": "托克托县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150123,
                      "name": "和林格尔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150124,
                      "name": "清水河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150125,
                      "name": "武川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huhehaote",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080200,
              "name": "包头",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150202,
                      "name": "东河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150203,
                      "name": "昆都仑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150204,
                      "name": "青山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150205,
                      "name": "石拐区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150206,
                      "name": "白云鄂博矿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150207,
                      "name": "九原区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150221,
                      "name": "土默特右旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150222,
                      "name": "固阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150223,
                      "name": "达尔罕茂明安联合旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baotou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080300,
              "name": "乌海",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150302,
                      "name": "海勃湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150303,
                      "name": "海南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150304,
                      "name": "乌达区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuhai",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080400,
              "name": "通辽",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150502,
                      "name": "科尔沁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150521,
                      "name": "科尔沁左翼中旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150522,
                      "name": "科尔沁左翼后旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150523,
                      "name": "开鲁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150524,
                      "name": "库伦旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150525,
                      "name": "奈曼旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150526,
                      "name": "扎鲁特旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150581,
                      "name": "霍林郭勒市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tongliao",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080500,
              "name": "赤峰",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150402,
                      "name": "红山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150403,
                      "name": "元宝山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150404,
                      "name": "松山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150421,
                      "name": "阿鲁科尔沁旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150422,
                      "name": "巴林左旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150423,
                      "name": "巴林右旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150424,
                      "name": "林西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150425,
                      "name": "克什克腾旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150426,
                      "name": "翁牛特旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150428,
                      "name": "喀喇沁旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150429,
                      "name": "宁城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150430,
                      "name": "敖汉旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chifeng",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080600,
              "name": "鄂尔多斯",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150602,
                      "name": "东胜区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150603,
                      "name": "康巴什区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150621,
                      "name": "达拉特旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150622,
                      "name": "准格尔旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150623,
                      "name": "鄂托克前旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150624,
                      "name": "鄂托克旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150625,
                      "name": "杭锦旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150626,
                      "name": "乌审旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150627,
                      "name": "伊金霍洛旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "e",
              "pinyin": "eerduosi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080700,
              "name": "呼伦贝尔",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150702,
                      "name": "海拉尔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150703,
                      "name": "扎赉诺尔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150721,
                      "name": "阿荣旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150722,
                      "name": "莫力达瓦达斡尔族自治旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150723,
                      "name": "鄂伦春自治旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150724,
                      "name": "鄂温克族自治旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150725,
                      "name": "陈巴尔虎旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150726,
                      "name": "新巴尔虎左旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150727,
                      "name": "新巴尔虎右旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150781,
                      "name": "满洲里市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150782,
                      "name": "牙克石市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150783,
                      "name": "扎兰屯市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150784,
                      "name": "额尔古纳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "e",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150785,
                      "name": "根河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hulunbeier",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080800,
              "name": "巴彦淖尔",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150802,
                      "name": "临河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150821,
                      "name": "五原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150822,
                      "name": "磴口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150823,
                      "name": "乌拉特前旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150824,
                      "name": "乌拉特中旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150825,
                      "name": "乌拉特后旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150826,
                      "name": "杭锦后旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bayannaoer",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080900,
              "name": "乌兰察布",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 150902,
                      "name": "集宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150921,
                      "name": "卓资县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150922,
                      "name": "化德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150923,
                      "name": "商都县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150924,
                      "name": "兴和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150925,
                      "name": "凉城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150926,
                      "name": "察哈尔右翼前旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150927,
                      "name": "察哈尔右翼中旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150928,
                      "name": "察哈尔右翼后旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150929,
                      "name": "四子王旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 150981,
                      "name": "丰镇市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wulanchabu",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081000,
              "name": "锡林郭勒盟",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 152501,
                      "name": "二连浩特市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "e",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152502,
                      "name": "锡林浩特市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152522,
                      "name": "阿巴嘎旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152523,
                      "name": "苏尼特左旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152524,
                      "name": "苏尼特右旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152525,
                      "name": "东乌珠穆沁旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152526,
                      "name": "西乌珠穆沁旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152527,
                      "name": "太仆寺旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152528,
                      "name": "镶黄旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152529,
                      "name": "正镶白旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152530,
                      "name": "正蓝旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152531,
                      "name": "多伦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xilinguole",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081100,
              "name": "兴安盟",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 152201,
                      "name": "乌兰浩特市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152202,
                      "name": "阿尔山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152221,
                      "name": "科尔沁右翼前旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152222,
                      "name": "科尔沁右翼中旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152223,
                      "name": "扎赉特旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152224,
                      "name": "突泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xinganmeng",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081200,
              "name": "阿拉善盟",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 152921,
                      "name": "阿拉善左旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152922,
                      "name": "阿拉善右旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 152923,
                      "name": "额济纳旗",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "alashan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "n",
      "pinyin": null,
      "rank": 5,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101090000,
      "name": "河北",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101090100,
              "name": "石家庄",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130102,
                      "name": "长安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130104,
                      "name": "桥西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130105,
                      "name": "新华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130107,
                      "name": "井陉矿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130108,
                      "name": "裕华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130109,
                      "name": "藁城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130110,
                      "name": "鹿泉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130111,
                      "name": "栾城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130121,
                      "name": "井陉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130123,
                      "name": "正定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130125,
                      "name": "行唐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130126,
                      "name": "灵寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130127,
                      "name": "高邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130128,
                      "name": "深泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130129,
                      "name": "赞皇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130130,
                      "name": "无极县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130131,
                      "name": "平山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130132,
                      "name": "元氏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130133,
                      "name": "赵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 19,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130181,
                      "name": "辛集市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 20,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130183,
                      "name": "晋州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 21,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130184,
                      "name": "新乐市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 22,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shijiazhuang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090200,
              "name": "保定",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130602,
                      "name": "竞秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130606,
                      "name": "莲池区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130607,
                      "name": "满城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130608,
                      "name": "清苑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130609,
                      "name": "徐水区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130623,
                      "name": "涞水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130624,
                      "name": "阜平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130626,
                      "name": "定兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130627,
                      "name": "唐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130628,
                      "name": "高阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130629,
                      "name": "容城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130630,
                      "name": "涞源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130631,
                      "name": "望都县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130632,
                      "name": "安新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130633,
                      "name": "易县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130634,
                      "name": "曲阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130635,
                      "name": "蠡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130636,
                      "name": "顺平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130637,
                      "name": "博野县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 19,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130638,
                      "name": "雄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 20,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130681,
                      "name": "涿州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 21,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130682,
                      "name": "定州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 22,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130683,
                      "name": "安国市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 23,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130684,
                      "name": "高碑店市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 24,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baoding",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090300,
              "name": "张家口",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130702,
                      "name": "桥东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130703,
                      "name": "桥西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130705,
                      "name": "宣化区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130706,
                      "name": "下花园区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130708,
                      "name": "万全区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130709,
                      "name": "崇礼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130722,
                      "name": "张北县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130723,
                      "name": "康保县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130724,
                      "name": "沽源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130725,
                      "name": "尚义县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130726,
                      "name": "蔚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130727,
                      "name": "阳原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130728,
                      "name": "怀安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130730,
                      "name": "怀来县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130731,
                      "name": "涿鹿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130732,
                      "name": "赤城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhangjiakou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090400,
              "name": "承德",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130802,
                      "name": "双桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130803,
                      "name": "双滦区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130804,
                      "name": "鹰手营子矿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130821,
                      "name": "承德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130822,
                      "name": "兴隆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130824,
                      "name": "滦平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130825,
                      "name": "隆化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130826,
                      "name": "丰宁满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130827,
                      "name": "宽城满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130828,
                      "name": "围场满族蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130881,
                      "name": "平泉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chengde",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090500,
              "name": "唐山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130202,
                      "name": "路南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130203,
                      "name": "路北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130204,
                      "name": "古冶区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130205,
                      "name": "开平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130207,
                      "name": "丰南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130208,
                      "name": "丰润区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130209,
                      "name": "曹妃甸区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130224,
                      "name": "滦南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130225,
                      "name": "乐亭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130227,
                      "name": "迁西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130229,
                      "name": "玉田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130281,
                      "name": "遵化市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130283,
                      "name": "迁安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130284,
                      "name": "滦州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tangshan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090600,
              "name": "廊坊",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 131002,
                      "name": "安次区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131003,
                      "name": "广阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131022,
                      "name": "固安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131023,
                      "name": "永清县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131024,
                      "name": "香河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131025,
                      "name": "大城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131026,
                      "name": "文安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131028,
                      "name": "大厂回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131081,
                      "name": "霸州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131082,
                      "name": "三河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "langfang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090700,
              "name": "沧州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130902,
                      "name": "新华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130903,
                      "name": "运河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130921,
                      "name": "沧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130922,
                      "name": "青县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130923,
                      "name": "东光县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130924,
                      "name": "海兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130925,
                      "name": "盐山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130926,
                      "name": "肃宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130927,
                      "name": "南皮县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130928,
                      "name": "吴桥县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130929,
                      "name": "献县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130930,
                      "name": "孟村回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130981,
                      "name": "泊头市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130982,
                      "name": "任丘市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130983,
                      "name": "黄骅市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130984,
                      "name": "河间市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "cangzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090800,
              "name": "衡水",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 131102,
                      "name": "桃城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131103,
                      "name": "冀州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131121,
                      "name": "枣强县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131122,
                      "name": "武邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131123,
                      "name": "武强县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131124,
                      "name": "饶阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131125,
                      "name": "安平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131126,
                      "name": "故城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131127,
                      "name": "景县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131128,
                      "name": "阜城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 131182,
                      "name": "深州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hengshui",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090900,
              "name": "邢台",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130502,
                      "name": "襄都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130503,
                      "name": "信都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130522,
                      "name": "临城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130523,
                      "name": "内丘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130524,
                      "name": "柏乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130525,
                      "name": "隆尧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130526,
                      "name": "任泽区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130527,
                      "name": "南和区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130528,
                      "name": "宁晋县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130529,
                      "name": "巨鹿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130530,
                      "name": "新河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130531,
                      "name": "广宗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130532,
                      "name": "平乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130533,
                      "name": "威县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130534,
                      "name": "清河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130535,
                      "name": "临西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130581,
                      "name": "南宫市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130582,
                      "name": "沙河市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 19,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xingtai",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101091000,
              "name": "邯郸",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130402,
                      "name": "邯山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130403,
                      "name": "丛台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130404,
                      "name": "复兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130406,
                      "name": "峰峰矿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130407,
                      "name": "肥乡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130408,
                      "name": "永年区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130423,
                      "name": "临漳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130424,
                      "name": "成安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130425,
                      "name": "大名县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130426,
                      "name": "涉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130427,
                      "name": "磁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130430,
                      "name": "邱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130431,
                      "name": "鸡泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130432,
                      "name": "广平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130433,
                      "name": "馆陶县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130434,
                      "name": "魏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130435,
                      "name": "曲周县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130481,
                      "name": "武安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "handan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101091100,
              "name": "秦皇岛",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 130302,
                      "name": "海港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130303,
                      "name": "山海关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130304,
                      "name": "北戴河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130306,
                      "name": "抚宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130321,
                      "name": "青龙满族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130322,
                      "name": "昌黎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 130324,
                      "name": "卢龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qinhuangdao",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 3,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101100000,
      "name": "山西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101100100,
              "name": "太原",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140105,
                      "name": "小店区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140106,
                      "name": "迎泽区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140107,
                      "name": "杏花岭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140108,
                      "name": "尖草坪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140109,
                      "name": "万柏林区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140110,
                      "name": "晋源区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140121,
                      "name": "清徐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140122,
                      "name": "阳曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140123,
                      "name": "娄烦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140181,
                      "name": "古交市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "taiyuan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100200,
              "name": "大同",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140212,
                      "name": "新荣区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140213,
                      "name": "平城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140214,
                      "name": "云冈区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140215,
                      "name": "云州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140221,
                      "name": "阳高县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140222,
                      "name": "天镇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140223,
                      "name": "广灵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140224,
                      "name": "灵丘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140225,
                      "name": "浑源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140226,
                      "name": "左云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "datong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100300,
              "name": "阳泉",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140302,
                      "name": "城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140303,
                      "name": "矿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140311,
                      "name": "郊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140321,
                      "name": "平定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140322,
                      "name": "盂县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yangquan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100400,
              "name": "晋中",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140702,
                      "name": "榆次区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140703,
                      "name": "太谷区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140721,
                      "name": "榆社县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140722,
                      "name": "左权县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140723,
                      "name": "和顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140724,
                      "name": "昔阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140725,
                      "name": "寿阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140727,
                      "name": "祁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140728,
                      "name": "平遥县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140729,
                      "name": "灵石县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140781,
                      "name": "介休市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jinzhong",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100500,
              "name": "长治",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140402,
                      "name": "潞州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140404,
                      "name": "上党区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140405,
                      "name": "屯留区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140406,
                      "name": "潞城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140423,
                      "name": "襄垣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140425,
                      "name": "平顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140426,
                      "name": "黎城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140427,
                      "name": "壶关县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140428,
                      "name": "长子县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140429,
                      "name": "武乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140430,
                      "name": "沁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140431,
                      "name": "沁源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "zhangzhi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100600,
              "name": "晋城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140502,
                      "name": "城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140521,
                      "name": "沁水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140522,
                      "name": "阳城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140524,
                      "name": "陵川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140525,
                      "name": "泽州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140581,
                      "name": "高平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jincheng",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100700,
              "name": "临汾",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 141002,
                      "name": "尧都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141021,
                      "name": "曲沃县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141022,
                      "name": "翼城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141023,
                      "name": "襄汾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141024,
                      "name": "洪洞县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141025,
                      "name": "古县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141026,
                      "name": "安泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141027,
                      "name": "浮山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141028,
                      "name": "吉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141029,
                      "name": "乡宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141030,
                      "name": "大宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141031,
                      "name": "隰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141032,
                      "name": "永和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141033,
                      "name": "蒲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141034,
                      "name": "汾西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141081,
                      "name": "侯马市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141082,
                      "name": "霍州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "linfen",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100800,
              "name": "运城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140802,
                      "name": "盐湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140821,
                      "name": "临猗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140822,
                      "name": "万荣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140823,
                      "name": "闻喜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140824,
                      "name": "稷山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140825,
                      "name": "新绛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140826,
                      "name": "绛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140827,
                      "name": "垣曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140828,
                      "name": "夏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140829,
                      "name": "平陆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140830,
                      "name": "芮城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140881,
                      "name": "永济市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140882,
                      "name": "河津市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yuncheng",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100900,
              "name": "朔州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140602,
                      "name": "朔城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140603,
                      "name": "平鲁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140621,
                      "name": "山阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140622,
                      "name": "应县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140623,
                      "name": "右玉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140681,
                      "name": "怀仁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shuozhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101101000,
              "name": "忻州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 140902,
                      "name": "忻府区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140921,
                      "name": "定襄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140922,
                      "name": "五台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140923,
                      "name": "代县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140924,
                      "name": "繁峙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140925,
                      "name": "宁武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140926,
                      "name": "静乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140927,
                      "name": "神池县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140928,
                      "name": "五寨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140929,
                      "name": "岢岚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140930,
                      "name": "河曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140931,
                      "name": "保德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140932,
                      "name": "偏关县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 140981,
                      "name": "原平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xinzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101101100,
              "name": "吕梁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 141102,
                      "name": "离石区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141121,
                      "name": "文水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141122,
                      "name": "交城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141123,
                      "name": "兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141124,
                      "name": "临县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141125,
                      "name": "柳林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141126,
                      "name": "石楼县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141127,
                      "name": "岚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141128,
                      "name": "方山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141129,
                      "name": "中阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141130,
                      "name": "交口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141181,
                      "name": "孝义市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 141182,
                      "name": "汾阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lvliang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 4,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101110000,
      "name": "陕西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101110100,
              "name": "西安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610102,
                      "name": "新城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610103,
                      "name": "碑林区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610104,
                      "name": "莲湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610111,
                      "name": "灞桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610112,
                      "name": "未央区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610113,
                      "name": "雁塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610114,
                      "name": "阎良区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610115,
                      "name": "临潼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610116,
                      "name": "长安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610117,
                      "name": "高陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610118,
                      "name": "鄠邑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610122,
                      "name": "蓝田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610124,
                      "name": "周至县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xian",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110200,
              "name": "咸阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610402,
                      "name": "秦都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610403,
                      "name": "杨陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610404,
                      "name": "渭城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610422,
                      "name": "三原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610423,
                      "name": "泾阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610424,
                      "name": "乾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610425,
                      "name": "礼泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610426,
                      "name": "永寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610428,
                      "name": "长武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610429,
                      "name": "旬邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610430,
                      "name": "淳化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610431,
                      "name": "武功县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610481,
                      "name": "兴平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610482,
                      "name": "彬州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xianyang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110300,
              "name": "延安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610602,
                      "name": "宝塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610603,
                      "name": "安塞区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610621,
                      "name": "延长县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610622,
                      "name": "延川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610625,
                      "name": "志丹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610626,
                      "name": "吴起县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610627,
                      "name": "甘泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610628,
                      "name": "富县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610629,
                      "name": "洛川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610630,
                      "name": "宜川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610631,
                      "name": "黄龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610632,
                      "name": "黄陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610681,
                      "name": "子长市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yanan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110400,
              "name": "榆林",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610802,
                      "name": "榆阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610803,
                      "name": "横山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610822,
                      "name": "府谷县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610824,
                      "name": "靖边县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610825,
                      "name": "定边县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610826,
                      "name": "绥德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610827,
                      "name": "米脂县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610828,
                      "name": "佳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610829,
                      "name": "吴堡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610830,
                      "name": "清涧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610831,
                      "name": "子洲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610881,
                      "name": "神木市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yulin",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110500,
              "name": "渭南",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610502,
                      "name": "临渭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610503,
                      "name": "华州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610522,
                      "name": "潼关县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610523,
                      "name": "大荔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610524,
                      "name": "合阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610525,
                      "name": "澄城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610526,
                      "name": "蒲城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610527,
                      "name": "白水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610528,
                      "name": "富平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610581,
                      "name": "韩城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610582,
                      "name": "华阴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "weinan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110600,
              "name": "商洛",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 611002,
                      "name": "商州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611021,
                      "name": "洛南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611022,
                      "name": "丹凤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611023,
                      "name": "商南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611024,
                      "name": "山阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611025,
                      "name": "镇安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 611026,
                      "name": "柞水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shangluo",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110700,
              "name": "安康",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610902,
                      "name": "汉滨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610921,
                      "name": "汉阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610922,
                      "name": "石泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610923,
                      "name": "宁陕县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610924,
                      "name": "紫阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610925,
                      "name": "岚皋县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610926,
                      "name": "平利县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610927,
                      "name": "镇坪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610928,
                      "name": "旬阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610929,
                      "name": "白河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "ankang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110800,
              "name": "汉中",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610702,
                      "name": "汉台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610703,
                      "name": "南郑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610722,
                      "name": "城固县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610723,
                      "name": "洋县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610724,
                      "name": "西乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610725,
                      "name": "勉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610726,
                      "name": "宁强县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610727,
                      "name": "略阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610728,
                      "name": "镇巴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610729,
                      "name": "留坝县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610730,
                      "name": "佛坪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hanzhong",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110900,
              "name": "宝鸡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610302,
                      "name": "渭滨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610303,
                      "name": "金台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610304,
                      "name": "陈仓区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610322,
                      "name": "凤翔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610323,
                      "name": "岐山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610324,
                      "name": "扶风县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610326,
                      "name": "眉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610327,
                      "name": "陇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610328,
                      "name": "千阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610329,
                      "name": "麟游县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610330,
                      "name": "凤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610331,
                      "name": "太白县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baoji",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101111000,
              "name": "铜川",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 610202,
                      "name": "王益区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610203,
                      "name": "印台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610204,
                      "name": "耀州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 610222,
                      "name": "宜君县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tongchuan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 27,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101120000,
      "name": "山东",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101120100,
              "name": "济南",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370102,
                      "name": "历下区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370103,
                      "name": "市中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370104,
                      "name": "槐荫区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370105,
                      "name": "天桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370112,
                      "name": "历城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370113,
                      "name": "长清区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370114,
                      "name": "章丘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370115,
                      "name": "济阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370116,
                      "name": "莱芜区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370117,
                      "name": "钢城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370124,
                      "name": "平阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370126,
                      "name": "商河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jinan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120200,
              "name": "青岛",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370202,
                      "name": "市南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370203,
                      "name": "市北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370211,
                      "name": "黄岛区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370212,
                      "name": "崂山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370213,
                      "name": "李沧区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370214,
                      "name": "城阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370215,
                      "name": "即墨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370281,
                      "name": "胶州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370283,
                      "name": "平度市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370285,
                      "name": "莱西市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qingdao",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120300,
              "name": "淄博",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370302,
                      "name": "淄川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370303,
                      "name": "张店区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370304,
                      "name": "博山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370305,
                      "name": "临淄区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370306,
                      "name": "周村区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370321,
                      "name": "桓台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370322,
                      "name": "高青县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370323,
                      "name": "沂源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zibo",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120400,
              "name": "德州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371402,
                      "name": "德城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371403,
                      "name": "陵城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371422,
                      "name": "宁津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371423,
                      "name": "庆云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371424,
                      "name": "临邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371425,
                      "name": "齐河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371426,
                      "name": "平原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371427,
                      "name": "夏津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371428,
                      "name": "武城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371481,
                      "name": "乐陵市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371482,
                      "name": "禹城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dezhou",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120500,
              "name": "烟台",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370602,
                      "name": "芝罘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370611,
                      "name": "福山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370612,
                      "name": "牟平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370613,
                      "name": "莱山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370614,
                      "name": "蓬莱区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370681,
                      "name": "龙口市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370682,
                      "name": "莱阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370683,
                      "name": "莱州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370685,
                      "name": "招远市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370686,
                      "name": "栖霞市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370687,
                      "name": "海阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yantai",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120600,
              "name": "潍坊",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370702,
                      "name": "潍城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370703,
                      "name": "寒亭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370704,
                      "name": "坊子区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370705,
                      "name": "奎文区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370724,
                      "name": "临朐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370725,
                      "name": "昌乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370781,
                      "name": "青州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370782,
                      "name": "诸城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370783,
                      "name": "寿光市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370784,
                      "name": "安丘市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370785,
                      "name": "高密市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370786,
                      "name": "昌邑市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "weifang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120700,
              "name": "济宁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370811,
                      "name": "任城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370812,
                      "name": "兖州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370826,
                      "name": "微山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370827,
                      "name": "鱼台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370828,
                      "name": "金乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370829,
                      "name": "嘉祥县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370830,
                      "name": "汶上县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370831,
                      "name": "泗水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370832,
                      "name": "梁山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370881,
                      "name": "曲阜市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370883,
                      "name": "邹城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jining",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120800,
              "name": "泰安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370902,
                      "name": "泰山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370911,
                      "name": "岱岳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370921,
                      "name": "宁阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370923,
                      "name": "东平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370982,
                      "name": "新泰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370983,
                      "name": "肥城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "taian",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120900,
              "name": "临沂",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371302,
                      "name": "兰山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371311,
                      "name": "罗庄区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371312,
                      "name": "河东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371321,
                      "name": "沂南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371322,
                      "name": "郯城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371323,
                      "name": "沂水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371324,
                      "name": "兰陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371325,
                      "name": "费县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371326,
                      "name": "平邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371327,
                      "name": "莒南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371328,
                      "name": "蒙阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371329,
                      "name": "临沭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "linyi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121000,
              "name": "菏泽",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371702,
                      "name": "牡丹区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371703,
                      "name": "定陶区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371721,
                      "name": "曹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371722,
                      "name": "单县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371723,
                      "name": "成武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371724,
                      "name": "巨野县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371725,
                      "name": "郓城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371726,
                      "name": "鄄城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371728,
                      "name": "东明县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "heze",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121100,
              "name": "滨州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371602,
                      "name": "滨城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371603,
                      "name": "沾化区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371621,
                      "name": "惠民县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371622,
                      "name": "阳信县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371623,
                      "name": "无棣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371625,
                      "name": "博兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371681,
                      "name": "邹平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "binzhou",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121200,
              "name": "东营",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370502,
                      "name": "东营区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370503,
                      "name": "河口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370505,
                      "name": "垦利区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370522,
                      "name": "利津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370523,
                      "name": "广饶县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dongying",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121300,
              "name": "威海",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371002,
                      "name": "环翠区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371003,
                      "name": "文登区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371082,
                      "name": "荣成市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371083,
                      "name": "乳山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "weihai",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121400,
              "name": "枣庄",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 370402,
                      "name": "市中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370403,
                      "name": "薛城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370404,
                      "name": "峄城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370405,
                      "name": "台儿庄区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370406,
                      "name": "山亭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 370481,
                      "name": "滕州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zaozhuang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121500,
              "name": "日照",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371102,
                      "name": "东港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371103,
                      "name": "岚山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371121,
                      "name": "五莲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371122,
                      "name": "莒县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "r",
              "pinyin": "rizhao",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121700,
              "name": "聊城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 371502,
                      "name": "东昌府区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371503,
                      "name": "茌平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371521,
                      "name": "阳谷县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371522,
                      "name": "莘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371524,
                      "name": "东阿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371525,
                      "name": "冠县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371526,
                      "name": "高唐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 371581,
                      "name": "临清市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liaocheng",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 15,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101130000,
      "name": "新疆",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101130100,
              "name": "乌鲁木齐",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 650102,
                      "name": "天山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650103,
                      "name": "沙依巴克区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650104,
                      "name": "新市区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650105,
                      "name": "水磨沟区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650106,
                      "name": "头屯河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650107,
                      "name": "达坂城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650109,
                      "name": "米东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650121,
                      "name": "乌鲁木齐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wulumuqi",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130200,
              "name": "克拉玛依",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 650202,
                      "name": "独山子区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650203,
                      "name": "克拉玛依区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650204,
                      "name": "白碱滩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650205,
                      "name": "乌尔禾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "k",
              "pinyin": "kelamayi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130300,
              "name": "昌吉回族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 652301,
                      "name": "昌吉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652302,
                      "name": "阜康市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652323,
                      "name": "呼图壁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652324,
                      "name": "玛纳斯县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652325,
                      "name": "奇台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652327,
                      "name": "吉木萨尔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652328,
                      "name": "木垒哈萨克自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changji",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130400,
              "name": "巴音郭楞蒙古自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 652801,
                      "name": "库尔勒市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652822,
                      "name": "轮台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652823,
                      "name": "尉犁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652824,
                      "name": "若羌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652825,
                      "name": "且末县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652826,
                      "name": "焉耆回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652827,
                      "name": "和静县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652828,
                      "name": "和硕县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652829,
                      "name": "博湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bayinguoleng",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130500,
              "name": "博尔塔拉蒙古自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 652701,
                      "name": "博乐市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652702,
                      "name": "阿拉山口市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652722,
                      "name": "精河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652723,
                      "name": "温泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "boertala",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130600,
              "name": "伊犁哈萨克自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 654002,
                      "name": "伊宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654003,
                      "name": "奎屯市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654004,
                      "name": "霍尔果斯市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654021,
                      "name": "伊宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654022,
                      "name": "察布查尔锡伯自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654023,
                      "name": "霍城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654024,
                      "name": "巩留县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654025,
                      "name": "新源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654026,
                      "name": "昭苏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654027,
                      "name": "特克斯县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654028,
                      "name": "尼勒克县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yili",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130800,
              "name": "吐鲁番",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 650402,
                      "name": "高昌区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650421,
                      "name": "鄯善县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650422,
                      "name": "托克逊县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tulufan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130900,
              "name": "哈密",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 650502,
                      "name": "伊州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650521,
                      "name": "巴里坤哈萨克自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 650522,
                      "name": "伊吾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hami",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131000,
              "name": "阿克苏地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 652901,
                      "name": "阿克苏市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652902,
                      "name": "库车市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652922,
                      "name": "温宿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652924,
                      "name": "沙雅县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652925,
                      "name": "新和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652926,
                      "name": "拜城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652927,
                      "name": "乌什县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652928,
                      "name": "阿瓦提县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 652929,
                      "name": "柯坪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "akesu",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131100,
              "name": "克孜勒苏柯尔克孜自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 653001,
                      "name": "阿图什市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653022,
                      "name": "阿克陶县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653023,
                      "name": "阿合奇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653024,
                      "name": "乌恰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "k",
              "pinyin": "kezilesukeerkezi",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131200,
              "name": "喀什地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 653101,
                      "name": "喀什市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653121,
                      "name": "疏附县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653122,
                      "name": "疏勒县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653123,
                      "name": "英吉沙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653124,
                      "name": "泽普县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653125,
                      "name": "莎车县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653126,
                      "name": "叶城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653127,
                      "name": "麦盖提县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653128,
                      "name": "岳普湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653129,
                      "name": "伽师县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653130,
                      "name": "巴楚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653131,
                      "name": "塔什库尔干塔吉克自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "k",
              "pinyin": "kashen",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131300,
              "name": "和田地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 653201,
                      "name": "和田市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653221,
                      "name": "和田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653222,
                      "name": "墨玉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653223,
                      "name": "皮山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653224,
                      "name": "洛浦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653225,
                      "name": "策勒县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653226,
                      "name": "于田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 653227,
                      "name": "民丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hetian",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131400,
              "name": "塔城地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 654201,
                      "name": "塔城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654202,
                      "name": "乌苏市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654221,
                      "name": "额敏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654223,
                      "name": "沙湾市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654224,
                      "name": "托里县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654225,
                      "name": "裕民县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654226,
                      "name": "和布克赛尔蒙古自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tacheng",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131500,
              "name": "阿勒泰地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 654301,
                      "name": "阿勒泰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654321,
                      "name": "布尔津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654322,
                      "name": "富蕴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654323,
                      "name": "福海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654324,
                      "name": "哈巴河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654325,
                      "name": "青河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 654326,
                      "name": "吉木乃县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "aletai",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131600,
              "name": "石河子",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "s",
              "pinyin": "shihezi",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131700,
              "name": "阿拉尔",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "a",
              "pinyin": "alaer",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131800,
              "name": "图木舒克",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "t",
              "pinyin": "tumushuke",
              "rank": 22,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131900,
              "name": "五家渠",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "w",
              "pinyin": "wujiaqu",
              "rank": 23,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132000,
              "name": "铁门关",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "t",
              "pinyin": "tiemenguan",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132100,
              "name": "北屯市",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "b",
              "pinyin": "beitunshi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132200,
              "name": "可克达拉市",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "k",
              "pinyin": "kekedalashi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132300,
              "name": "昆玉市",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "k",
              "pinyin": "kunyushi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132400,
              "name": "双河市",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "s",
              "pinyin": "shuangheshi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132500,
              "name": "新星市",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "x",
              "pinyin": "xinxingshi",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 31,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101140000,
      "name": "西藏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101140100,
              "name": "拉萨",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540102,
                      "name": "城关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540103,
                      "name": "堆龙德庆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540104,
                      "name": "达孜区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540121,
                      "name": "林周县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540122,
                      "name": "当雄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540123,
                      "name": "尼木县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540124,
                      "name": "曲水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540127,
                      "name": "墨竹工卡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lasa",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140200,
              "name": "日喀则",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540202,
                      "name": "桑珠孜区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540221,
                      "name": "南木林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540222,
                      "name": "江孜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540223,
                      "name": "定日县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540224,
                      "name": "萨迦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540225,
                      "name": "拉孜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540226,
                      "name": "昂仁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540227,
                      "name": "谢通门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540228,
                      "name": "白朗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540229,
                      "name": "仁布县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540230,
                      "name": "康马县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540231,
                      "name": "定结县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540232,
                      "name": "仲巴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540233,
                      "name": "亚东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540234,
                      "name": "吉隆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540235,
                      "name": "聂拉木县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540236,
                      "name": "萨嘎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540237,
                      "name": "岗巴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "r",
              "pinyin": "rikaze",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140300,
              "name": "昌都",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540302,
                      "name": "卡若区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540321,
                      "name": "江达县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540322,
                      "name": "贡觉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540323,
                      "name": "类乌齐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540324,
                      "name": "丁青县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540325,
                      "name": "察雅县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540326,
                      "name": "八宿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540327,
                      "name": "左贡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540328,
                      "name": "芒康县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540329,
                      "name": "洛隆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540330,
                      "name": "边坝县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changdou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140400,
              "name": "林芝",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540402,
                      "name": "巴宜区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540421,
                      "name": "工布江达县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540422,
                      "name": "米林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540423,
                      "name": "墨脱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540424,
                      "name": "波密县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540425,
                      "name": "察隅县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540426,
                      "name": "朗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "linzhi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140500,
              "name": "山南",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540502,
                      "name": "乃东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540521,
                      "name": "扎囊县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540522,
                      "name": "贡嘎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540523,
                      "name": "桑日县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540524,
                      "name": "琼结县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540525,
                      "name": "曲松县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540526,
                      "name": "措美县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540527,
                      "name": "洛扎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540528,
                      "name": "加查县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540529,
                      "name": "隆子县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540530,
                      "name": "错那县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 540531,
                      "name": "浪卡子县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shannan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140600,
              "name": "那曲",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 540602,
                      "name": "色尼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542422,
                      "name": "嘉黎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542423,
                      "name": "比如县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542424,
                      "name": "聂荣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542425,
                      "name": "安多县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542426,
                      "name": "申扎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542427,
                      "name": "索县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542428,
                      "name": "班戈县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542429,
                      "name": "巴青县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542430,
                      "name": "尼玛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542431,
                      "name": "双湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "neiqu",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140700,
              "name": "阿里地区",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 542521,
                      "name": "普兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542522,
                      "name": "札达县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542523,
                      "name": "噶尔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542524,
                      "name": "日土县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542525,
                      "name": "革吉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542526,
                      "name": "改则县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 542527,
                      "name": "措勤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "ali",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 26,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101150000,
      "name": "青海",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101150100,
              "name": "西宁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 630102,
                      "name": "城东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630103,
                      "name": "城中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630104,
                      "name": "城西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630105,
                      "name": "城北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630106,
                      "name": "湟中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630121,
                      "name": "大通回族土族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630123,
                      "name": "湟源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xining",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150200,
              "name": "海东",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 630202,
                      "name": "乐都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630203,
                      "name": "平安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630222,
                      "name": "民和回族土族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630223,
                      "name": "互助土族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630224,
                      "name": "化隆回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 630225,
                      "name": "循化撒拉族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "haidong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150300,
              "name": "海北藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632221,
                      "name": "门源回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632222,
                      "name": "祁连县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632223,
                      "name": "海晏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632224,
                      "name": "刚察县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "haibei",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150400,
              "name": "黄南藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632321,
                      "name": "同仁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632322,
                      "name": "尖扎县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632323,
                      "name": "泽库县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632324,
                      "name": "河南蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huangnan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150500,
              "name": "海南藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632521,
                      "name": "共和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632522,
                      "name": "同德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632523,
                      "name": "贵德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632524,
                      "name": "兴海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632525,
                      "name": "贵南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hainan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150600,
              "name": "果洛藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632621,
                      "name": "玛沁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632622,
                      "name": "班玛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632623,
                      "name": "甘德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632624,
                      "name": "达日县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632625,
                      "name": "久治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632626,
                      "name": "玛多县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guoluo",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150700,
              "name": "玉树藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632701,
                      "name": "玉树市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632722,
                      "name": "杂多县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632723,
                      "name": "称多县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632724,
                      "name": "治多县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632725,
                      "name": "囊谦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632726,
                      "name": "曲麻莱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yushu",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150800,
              "name": "海西蒙古族藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 632801,
                      "name": "格尔木市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632802,
                      "name": "德令哈市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632803,
                      "name": "茫崖市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632821,
                      "name": "乌兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632822,
                      "name": "都兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632823,
                      "name": "天峻县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 632825,
                      "name": "海西蒙古族藏族自治州直辖",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "haixi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "q",
      "pinyin": null,
      "rank": 29,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101160000,
      "name": "甘肃",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101160100,
              "name": "兰州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620102,
                      "name": "城关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620103,
                      "name": "七里河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620104,
                      "name": "西固区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620105,
                      "name": "安宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620111,
                      "name": "红古区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620121,
                      "name": "永登县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620122,
                      "name": "皋兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620123,
                      "name": "榆中县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lanzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160200,
              "name": "定西",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 621102,
                      "name": "安定区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621121,
                      "name": "通渭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621122,
                      "name": "陇西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621123,
                      "name": "渭源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621124,
                      "name": "临洮县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621125,
                      "name": "漳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621126,
                      "name": "岷县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dingxi",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160300,
              "name": "平凉",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620802,
                      "name": "崆峒区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620821,
                      "name": "泾川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620822,
                      "name": "灵台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620823,
                      "name": "崇信县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620825,
                      "name": "庄浪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620826,
                      "name": "静宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620881,
                      "name": "华亭市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "pingliang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160400,
              "name": "庆阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 621002,
                      "name": "西峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621021,
                      "name": "庆城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621022,
                      "name": "环县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621023,
                      "name": "华池县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621024,
                      "name": "合水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621025,
                      "name": "正宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621026,
                      "name": "宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621027,
                      "name": "镇原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qingyang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160500,
              "name": "武威",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620602,
                      "name": "凉州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620621,
                      "name": "民勤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620622,
                      "name": "古浪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620623,
                      "name": "天祝藏族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuwei",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160600,
              "name": "金昌",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620302,
                      "name": "金川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620321,
                      "name": "永昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jinchang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160700,
              "name": "张掖",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620702,
                      "name": "甘州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620721,
                      "name": "肃南裕固族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620722,
                      "name": "民乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620723,
                      "name": "临泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620724,
                      "name": "高台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620725,
                      "name": "山丹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhangye",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160800,
              "name": "酒泉",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620902,
                      "name": "肃州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620921,
                      "name": "金塔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620922,
                      "name": "瓜州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620923,
                      "name": "肃北蒙古族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620924,
                      "name": "阿克塞哈萨克族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620981,
                      "name": "玉门市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620982,
                      "name": "敦煌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiuquan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160900,
              "name": "天水",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620502,
                      "name": "秦州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620503,
                      "name": "麦积区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620521,
                      "name": "清水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620522,
                      "name": "秦安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620523,
                      "name": "甘谷县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620524,
                      "name": "武山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620525,
                      "name": "张家川回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tianshui",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161000,
              "name": "白银",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 620402,
                      "name": "白银区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620403,
                      "name": "平川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620421,
                      "name": "靖远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620422,
                      "name": "会宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 620423,
                      "name": "景泰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baiyin",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161100,
              "name": "陇南",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 621202,
                      "name": "武都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621221,
                      "name": "成县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621222,
                      "name": "文县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621223,
                      "name": "宕昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621224,
                      "name": "康县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621225,
                      "name": "西和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621226,
                      "name": "礼县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621227,
                      "name": "徽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 621228,
                      "name": "两当县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "longnan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161200,
              "name": "嘉峪关",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "j",
              "pinyin": "jiayuguan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161300,
              "name": "临夏回族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 622901,
                      "name": "临夏市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622921,
                      "name": "临夏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622922,
                      "name": "康乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622923,
                      "name": "永靖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622924,
                      "name": "广河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622925,
                      "name": "和政县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622926,
                      "name": "东乡族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 622927,
                      "name": "积石山保安族东乡族撒拉族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "linxia",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161400,
              "name": "甘南藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 623001,
                      "name": "合作市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623021,
                      "name": "临潭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623022,
                      "name": "卓尼县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623023,
                      "name": "舟曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623024,
                      "name": "迭部县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623025,
                      "name": "玛曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623026,
                      "name": "碌曲县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 623027,
                      "name": "夏河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "gannan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 28,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101170000,
      "name": "宁夏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101170100,
              "name": "银川",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 640104,
                      "name": "兴庆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640105,
                      "name": "西夏区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640106,
                      "name": "金凤区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640121,
                      "name": "永宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640122,
                      "name": "贺兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640181,
                      "name": "灵武市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yinchuan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170200,
              "name": "石嘴山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 640202,
                      "name": "大武口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640205,
                      "name": "惠农区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640221,
                      "name": "平罗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shizuishan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170300,
              "name": "吴忠",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 640302,
                      "name": "利通区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640303,
                      "name": "红寺堡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640323,
                      "name": "盐池县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640324,
                      "name": "同心县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640381,
                      "name": "青铜峡市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuzhong",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170400,
              "name": "固原",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 640402,
                      "name": "原州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640422,
                      "name": "西吉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640423,
                      "name": "隆德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640424,
                      "name": "泾源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640425,
                      "name": "彭阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guyuan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170500,
              "name": "中卫",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 640502,
                      "name": "沙坡头区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640521,
                      "name": "中宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 640522,
                      "name": "海原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhongwei",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "n",
      "pinyin": null,
      "rank": 30,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101180000,
      "name": "河南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101180100,
              "name": "郑州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410102,
                      "name": "中原区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410103,
                      "name": "二七区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410104,
                      "name": "管城回族区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410105,
                      "name": "金水区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410106,
                      "name": "上街区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410108,
                      "name": "惠济区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410122,
                      "name": "中牟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410181,
                      "name": "巩义市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410182,
                      "name": "荥阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410183,
                      "name": "新密市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410184,
                      "name": "新郑市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410185,
                      "name": "登封市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhengzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180200,
              "name": "安阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410502,
                      "name": "文峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410503,
                      "name": "北关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410505,
                      "name": "殷都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410506,
                      "name": "龙安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410522,
                      "name": "安阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410523,
                      "name": "汤阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410526,
                      "name": "滑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410527,
                      "name": "内黄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410581,
                      "name": "林州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "anyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180300,
              "name": "新乡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410702,
                      "name": "红旗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410703,
                      "name": "卫滨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410704,
                      "name": "凤泉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410711,
                      "name": "牧野区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410721,
                      "name": "新乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410724,
                      "name": "获嘉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410725,
                      "name": "原阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410726,
                      "name": "延津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410727,
                      "name": "封丘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410781,
                      "name": "卫辉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410782,
                      "name": "辉县市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410783,
                      "name": "长垣市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xinxiang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180400,
              "name": "许昌",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411002,
                      "name": "魏都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411003,
                      "name": "建安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411024,
                      "name": "鄢陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411025,
                      "name": "襄城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411081,
                      "name": "禹州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411082,
                      "name": "长葛市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xuchang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180500,
              "name": "平顶山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410402,
                      "name": "新华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410403,
                      "name": "卫东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410404,
                      "name": "石龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410411,
                      "name": "湛河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410421,
                      "name": "宝丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410422,
                      "name": "叶县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410423,
                      "name": "鲁山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410425,
                      "name": "郏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410481,
                      "name": "舞钢市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410482,
                      "name": "汝州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "pingdingshan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180600,
              "name": "信阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411502,
                      "name": "浉河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411503,
                      "name": "平桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411521,
                      "name": "罗山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411522,
                      "name": "光山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411523,
                      "name": "新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411524,
                      "name": "商城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411525,
                      "name": "固始县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411526,
                      "name": "潢川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411527,
                      "name": "淮滨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411528,
                      "name": "息县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xinyang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180700,
              "name": "南阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411302,
                      "name": "宛城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411303,
                      "name": "卧龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411321,
                      "name": "南召县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411322,
                      "name": "方城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411323,
                      "name": "西峡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411324,
                      "name": "镇平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411325,
                      "name": "内乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411326,
                      "name": "淅川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411327,
                      "name": "社旗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411328,
                      "name": "唐河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411329,
                      "name": "新野县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411330,
                      "name": "桐柏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411381,
                      "name": "邓州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanyang",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180800,
              "name": "开封",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410202,
                      "name": "龙亭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410203,
                      "name": "顺河回族区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410204,
                      "name": "鼓楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410205,
                      "name": "禹王台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410212,
                      "name": "祥符区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410221,
                      "name": "杞县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410222,
                      "name": "通许县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410223,
                      "name": "尉氏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410225,
                      "name": "兰考县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "k",
              "pinyin": "kaifeng",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180900,
              "name": "洛阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410302,
                      "name": "老城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410303,
                      "name": "西工区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410304,
                      "name": "瀍河回族区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410305,
                      "name": "涧西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410308,
                      "name": "孟津区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410311,
                      "name": "洛龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410323,
                      "name": "新安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410324,
                      "name": "栾川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410325,
                      "name": "嵩县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410326,
                      "name": "汝阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410327,
                      "name": "宜阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410328,
                      "name": "洛宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410329,
                      "name": "伊川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410381,
                      "name": "偃师区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "luoyang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181000,
              "name": "商丘",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411402,
                      "name": "梁园区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411403,
                      "name": "睢阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411421,
                      "name": "民权县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411422,
                      "name": "睢县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411423,
                      "name": "宁陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411424,
                      "name": "柘城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411425,
                      "name": "虞城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411426,
                      "name": "夏邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411481,
                      "name": "永城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shangqiu",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181100,
              "name": "焦作",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410802,
                      "name": "解放区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410803,
                      "name": "中站区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410804,
                      "name": "马村区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410811,
                      "name": "山阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410821,
                      "name": "修武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410822,
                      "name": "博爱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410823,
                      "name": "武陟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410825,
                      "name": "温县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410882,
                      "name": "沁阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410883,
                      "name": "孟州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiaozuo",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181200,
              "name": "鹤壁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410602,
                      "name": "鹤山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410603,
                      "name": "山城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410611,
                      "name": "淇滨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410621,
                      "name": "浚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410622,
                      "name": "淇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hebi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181300,
              "name": "濮阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 410902,
                      "name": "华龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410922,
                      "name": "清丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410923,
                      "name": "南乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410926,
                      "name": "范县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410927,
                      "name": "台前县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 410928,
                      "name": "濮阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "puyang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181400,
              "name": "周口",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411602,
                      "name": "川汇区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411603,
                      "name": "淮阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411621,
                      "name": "扶沟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411622,
                      "name": "西华县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411623,
                      "name": "商水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411624,
                      "name": "沈丘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411625,
                      "name": "郸城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411627,
                      "name": "太康县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411628,
                      "name": "鹿邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411681,
                      "name": "项城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhoukou",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181500,
              "name": "漯河",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411102,
                      "name": "源汇区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411103,
                      "name": "郾城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411104,
                      "name": "召陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411121,
                      "name": "舞阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411122,
                      "name": "临颍县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "luohe",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181600,
              "name": "驻马店",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411702,
                      "name": "驿城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411721,
                      "name": "西平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411722,
                      "name": "上蔡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411723,
                      "name": "平舆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411724,
                      "name": "正阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411725,
                      "name": "确山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411726,
                      "name": "泌阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411727,
                      "name": "汝南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411728,
                      "name": "遂平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411729,
                      "name": "新蔡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhumadian",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181700,
              "name": "三门峡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 411202,
                      "name": "湖滨区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411203,
                      "name": "陕州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411221,
                      "name": "渑池县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411224,
                      "name": "卢氏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411281,
                      "name": "义马市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 411282,
                      "name": "灵宝市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "sanmenxia",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181800,
              "name": "济源",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "j",
              "pinyin": "jiyuan",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 16,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101190000,
      "name": "江苏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101190100,
              "name": "南京",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320102,
                      "name": "玄武区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320104,
                      "name": "秦淮区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320105,
                      "name": "建邺区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320106,
                      "name": "鼓楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320111,
                      "name": "浦口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320113,
                      "name": "栖霞区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320114,
                      "name": "雨花台区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320115,
                      "name": "江宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320116,
                      "name": "六合区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320117,
                      "name": "溧水区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320118,
                      "name": "高淳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanjing",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190200,
              "name": "无锡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320205,
                      "name": "锡山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320206,
                      "name": "惠山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320211,
                      "name": "滨湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320213,
                      "name": "梁溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320214,
                      "name": "新吴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320281,
                      "name": "江阴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320282,
                      "name": "宜兴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuxi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190300,
              "name": "镇江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 321102,
                      "name": "京口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321111,
                      "name": "润州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321112,
                      "name": "丹徒区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321181,
                      "name": "丹阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321182,
                      "name": "扬中市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321183,
                      "name": "句容市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhenjiang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190400,
              "name": "苏州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320505,
                      "name": "虎丘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320506,
                      "name": "吴中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320507,
                      "name": "相城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320508,
                      "name": "姑苏区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320509,
                      "name": "吴江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320571,
                      "name": "苏州工业园区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320581,
                      "name": "常熟市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320582,
                      "name": "张家港市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320583,
                      "name": "昆山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320585,
                      "name": "太仓市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190500,
              "name": "南通",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320602,
                      "name": "崇川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320612,
                      "name": "通州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320614,
                      "name": "海门区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320623,
                      "name": "如东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320681,
                      "name": "启东市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320682,
                      "name": "如皋市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320685,
                      "name": "海安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nantong",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190600,
              "name": "扬州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 321002,
                      "name": "广陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321003,
                      "name": "邗江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321012,
                      "name": "江都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321023,
                      "name": "宝应县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321081,
                      "name": "仪征市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321084,
                      "name": "高邮市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yangzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190700,
              "name": "盐城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320902,
                      "name": "亭湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320903,
                      "name": "盐都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320904,
                      "name": "大丰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320921,
                      "name": "响水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320922,
                      "name": "滨海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320923,
                      "name": "阜宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320924,
                      "name": "射阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320925,
                      "name": "建湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320981,
                      "name": "东台市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yancheng",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190800,
              "name": "徐州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320302,
                      "name": "鼓楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320303,
                      "name": "云龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320305,
                      "name": "贾汪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320311,
                      "name": "泉山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320312,
                      "name": "铜山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320321,
                      "name": "丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320322,
                      "name": "沛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320324,
                      "name": "睢宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320381,
                      "name": "新沂市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320382,
                      "name": "邳州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xuzhou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190900,
              "name": "淮安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320803,
                      "name": "淮安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320804,
                      "name": "淮阴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320812,
                      "name": "清江浦区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320813,
                      "name": "洪泽区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320826,
                      "name": "涟水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320830,
                      "name": "盱眙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320831,
                      "name": "金湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huaian",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191000,
              "name": "连云港",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320703,
                      "name": "连云区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320706,
                      "name": "海州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320707,
                      "name": "赣榆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320722,
                      "name": "东海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320723,
                      "name": "灌云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320724,
                      "name": "灌南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lianyungang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191100,
              "name": "常州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 320402,
                      "name": "天宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320404,
                      "name": "钟楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320411,
                      "name": "新北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320412,
                      "name": "武进区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320413,
                      "name": "金坛区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 320481,
                      "name": "溧阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191200,
              "name": "泰州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 321202,
                      "name": "海陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321203,
                      "name": "高港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321204,
                      "name": "姜堰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321281,
                      "name": "兴化市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321282,
                      "name": "靖江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321283,
                      "name": "泰兴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "taizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191300,
              "name": "宿迁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 321302,
                      "name": "宿城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321311,
                      "name": "宿豫区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321322,
                      "name": "沭阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321323,
                      "name": "泗阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 321324,
                      "name": "泗洪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suqian",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 10,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101200000,
      "name": "湖北",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101200100,
              "name": "武汉",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420102,
                      "name": "江岸区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420103,
                      "name": "江汉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420104,
                      "name": "硚口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420105,
                      "name": "汉阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420106,
                      "name": "武昌区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420107,
                      "name": "青山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420111,
                      "name": "洪山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420112,
                      "name": "东西湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420113,
                      "name": "汉南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420114,
                      "name": "蔡甸区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420115,
                      "name": "江夏区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420116,
                      "name": "黄陂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420117,
                      "name": "新洲区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuhan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200200,
              "name": "襄阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420602,
                      "name": "襄城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420606,
                      "name": "樊城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420607,
                      "name": "襄州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420624,
                      "name": "南漳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420625,
                      "name": "谷城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420626,
                      "name": "保康县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420682,
                      "name": "老河口市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420683,
                      "name": "枣阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420684,
                      "name": "宜城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xiangyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200300,
              "name": "鄂州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420702,
                      "name": "梁子湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420703,
                      "name": "华容区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420704,
                      "name": "鄂城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "e",
              "pinyin": "ezhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200400,
              "name": "孝感",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420902,
                      "name": "孝南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420921,
                      "name": "孝昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420922,
                      "name": "大悟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420923,
                      "name": "云梦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420981,
                      "name": "应城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420982,
                      "name": "安陆市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420984,
                      "name": "汉川市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xiaogan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200500,
              "name": "黄冈",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 421102,
                      "name": "黄州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421121,
                      "name": "团风县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421122,
                      "name": "红安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421123,
                      "name": "罗田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421124,
                      "name": "英山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421125,
                      "name": "浠水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421126,
                      "name": "蕲春县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421127,
                      "name": "黄梅县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421181,
                      "name": "麻城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421182,
                      "name": "武穴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huanggang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200600,
              "name": "黄石",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420202,
                      "name": "黄石港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420203,
                      "name": "西塞山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420204,
                      "name": "下陆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420205,
                      "name": "铁山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420222,
                      "name": "阳新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420281,
                      "name": "大冶市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huangshi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200700,
              "name": "咸宁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 421202,
                      "name": "咸安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421221,
                      "name": "嘉鱼县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421222,
                      "name": "通城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421223,
                      "name": "崇阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421224,
                      "name": "通山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421281,
                      "name": "赤壁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xianning",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200800,
              "name": "荆州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 421002,
                      "name": "沙市区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421003,
                      "name": "荆州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421022,
                      "name": "公安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421023,
                      "name": "监利市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421024,
                      "name": "江陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421081,
                      "name": "石首市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421083,
                      "name": "洪湖市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421087,
                      "name": "松滋市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jingzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200900,
              "name": "宜昌",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420502,
                      "name": "西陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420503,
                      "name": "伍家岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420504,
                      "name": "点军区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420505,
                      "name": "猇亭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420506,
                      "name": "夷陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420525,
                      "name": "远安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420526,
                      "name": "兴山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420527,
                      "name": "秭归县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420528,
                      "name": "长阳土家族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420529,
                      "name": "五峰土家族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420581,
                      "name": "宜都市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420582,
                      "name": "当阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420583,
                      "name": "枝江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yichang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201000,
              "name": "十堰",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420302,
                      "name": "茅箭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420303,
                      "name": "张湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420304,
                      "name": "郧阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420322,
                      "name": "郧西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420323,
                      "name": "竹山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420324,
                      "name": "竹溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420325,
                      "name": "房县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420381,
                      "name": "丹江口市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shiyan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201100,
              "name": "随州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 421303,
                      "name": "曾都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421321,
                      "name": "随县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 421381,
                      "name": "广水市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201200,
              "name": "荆门",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 420802,
                      "name": "东宝区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420804,
                      "name": "掇刀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420822,
                      "name": "沙洋县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420881,
                      "name": "钟祥市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 420882,
                      "name": "京山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jingmen",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201300,
              "name": "恩施土家族苗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 422801,
                      "name": "恩施市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "e",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422802,
                      "name": "利川市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422822,
                      "name": "建始县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422823,
                      "name": "巴东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422825,
                      "name": "宣恩县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422826,
                      "name": "咸丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422827,
                      "name": "来凤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 422828,
                      "name": "鹤峰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "e",
              "pinyin": "enshi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201400,
              "name": "仙桃",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "x",
              "pinyin": "xiantao",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201500,
              "name": "潜江",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "q",
              "pinyin": "qianjiang",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201600,
              "name": "天门",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "t",
              "pinyin": "tianmen",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201700,
              "name": "神农架",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "s",
              "pinyin": "shennongjia",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 17,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101210000,
      "name": "浙江",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101210100,
              "name": "杭州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330102,
                      "name": "上城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330105,
                      "name": "拱墅区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330106,
                      "name": "西湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330108,
                      "name": "滨江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330109,
                      "name": "萧山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330110,
                      "name": "余杭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330111,
                      "name": "富阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330112,
                      "name": "临安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330113,
                      "name": "临平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330114,
                      "name": "钱塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330122,
                      "name": "桐庐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 4,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330127,
                      "name": "淳安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330182,
                      "name": "建德市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hangzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210200,
              "name": "湖州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330502,
                      "name": "吴兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330503,
                      "name": "南浔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330521,
                      "name": "德清县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330522,
                      "name": "长兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330523,
                      "name": "安吉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210300,
              "name": "嘉兴",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330402,
                      "name": "南湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330411,
                      "name": "秀洲区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330421,
                      "name": "嘉善县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330424,
                      "name": "海盐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330481,
                      "name": "海宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330482,
                      "name": "平湖市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330483,
                      "name": "桐乡市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiaxing",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210400,
              "name": "宁波",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330203,
                      "name": "海曙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330205,
                      "name": "江北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330206,
                      "name": "北仑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330211,
                      "name": "镇海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330212,
                      "name": "鄞州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330213,
                      "name": "奉化区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330225,
                      "name": "象山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330226,
                      "name": "宁海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330281,
                      "name": "余姚市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330282,
                      "name": "慈溪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "ningbo",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210500,
              "name": "绍兴",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330602,
                      "name": "越城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330603,
                      "name": "柯桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330604,
                      "name": "上虞区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330624,
                      "name": "新昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330681,
                      "name": "诸暨市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330683,
                      "name": "嵊州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shaoxing",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210600,
              "name": "台州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 331002,
                      "name": "椒江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331003,
                      "name": "黄岩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331004,
                      "name": "路桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331022,
                      "name": "三门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331023,
                      "name": "天台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331024,
                      "name": "仙居县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331081,
                      "name": "温岭市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331082,
                      "name": "临海市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331083,
                      "name": "玉环市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "taizhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210700,
              "name": "温州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330302,
                      "name": "鹿城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330303,
                      "name": "龙湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330304,
                      "name": "瓯海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330305,
                      "name": "洞头区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330324,
                      "name": "永嘉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330326,
                      "name": "平阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330327,
                      "name": "苍南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330328,
                      "name": "文成县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330329,
                      "name": "泰顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330381,
                      "name": "瑞安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330382,
                      "name": "乐清市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330383,
                      "name": "龙港市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wenzhou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210800,
              "name": "丽水",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 331102,
                      "name": "莲都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331121,
                      "name": "青田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331122,
                      "name": "缙云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331123,
                      "name": "遂昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331124,
                      "name": "松阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331125,
                      "name": "云和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331126,
                      "name": "庆元县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331127,
                      "name": "景宁畲族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 331181,
                      "name": "龙泉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lishui",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210900,
              "name": "金华",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330702,
                      "name": "婺城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330703,
                      "name": "金东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330723,
                      "name": "武义县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330726,
                      "name": "浦江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330727,
                      "name": "磐安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330781,
                      "name": "兰溪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330782,
                      "name": "义乌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330783,
                      "name": "东阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330784,
                      "name": "永康市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jinhua",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101211000,
              "name": "衢州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330802,
                      "name": "柯城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330803,
                      "name": "衢江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330822,
                      "name": "常山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330824,
                      "name": "开化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330825,
                      "name": "龙游县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330881,
                      "name": "江山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "quzhou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101211100,
              "name": "舟山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 330902,
                      "name": "定海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330903,
                      "name": "普陀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330921,
                      "name": "岱山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 330922,
                      "name": "嵊泗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhoushan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "z",
      "pinyin": null,
      "rank": 11,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101220000,
      "name": "安徽",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101220100,
              "name": "合肥",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340102,
                      "name": "瑶海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340103,
                      "name": "庐阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340104,
                      "name": "蜀山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340111,
                      "name": "包河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340121,
                      "name": "长丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340122,
                      "name": "肥东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340123,
                      "name": "肥西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340124,
                      "name": "庐江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340181,
                      "name": "巢湖市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hefei",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220200,
              "name": "蚌埠",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340302,
                      "name": "龙子湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340303,
                      "name": "蚌山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340304,
                      "name": "禹会区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340311,
                      "name": "淮上区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340321,
                      "name": "怀远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340322,
                      "name": "五河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340323,
                      "name": "固镇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bangbu",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220300,
              "name": "芜湖",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340202,
                      "name": "镜湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340203,
                      "name": "弋江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340207,
                      "name": "鸠江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340221,
                      "name": "湾沚区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340222,
                      "name": "繁昌区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340223,
                      "name": "南陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340281,
                      "name": "无为市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuhu",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220400,
              "name": "淮南",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340402,
                      "name": "大通区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340403,
                      "name": "田家庵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340404,
                      "name": "谢家集区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340405,
                      "name": "八公山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340406,
                      "name": "潘集区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340421,
                      "name": "凤台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340422,
                      "name": "寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huainan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220500,
              "name": "马鞍山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340503,
                      "name": "花山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340504,
                      "name": "雨山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340506,
                      "name": "博望区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340521,
                      "name": "当涂县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340522,
                      "name": "含山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340523,
                      "name": "和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "maanshan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220600,
              "name": "安庆",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340802,
                      "name": "迎江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340803,
                      "name": "大观区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340811,
                      "name": "宜秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340822,
                      "name": "怀宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340825,
                      "name": "太湖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340826,
                      "name": "宿松县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340827,
                      "name": "望江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340828,
                      "name": "岳西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340881,
                      "name": "桐城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340882,
                      "name": "潜山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "anqing",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220700,
              "name": "宿州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341302,
                      "name": "埇桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341321,
                      "name": "砀山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341322,
                      "name": "萧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341323,
                      "name": "灵璧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341324,
                      "name": "泗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suzhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220800,
              "name": "阜阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341202,
                      "name": "颍州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341203,
                      "name": "颍东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341204,
                      "name": "颍泉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341221,
                      "name": "临泉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341222,
                      "name": "太和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341225,
                      "name": "阜南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341226,
                      "name": "颍上县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341282,
                      "name": "界首市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fuyang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220900,
              "name": "亳州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341602,
                      "name": "谯城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341621,
                      "name": "涡阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341622,
                      "name": "蒙城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341623,
                      "name": "利辛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bozhou",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221000,
              "name": "滁州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341102,
                      "name": "琅琊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341103,
                      "name": "南谯区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341122,
                      "name": "来安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341124,
                      "name": "全椒县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341125,
                      "name": "定远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341126,
                      "name": "凤阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341181,
                      "name": "天长市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341182,
                      "name": "明光市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chuzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221100,
              "name": "淮北",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340602,
                      "name": "杜集区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340603,
                      "name": "相山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340604,
                      "name": "烈山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340621,
                      "name": "濉溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huaibei",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221200,
              "name": "铜陵",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 340705,
                      "name": "铜官区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340706,
                      "name": "义安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340711,
                      "name": "郊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 340722,
                      "name": "枞阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tongling",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221300,
              "name": "宣城",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341802,
                      "name": "宣州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341821,
                      "name": "郎溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341823,
                      "name": "泾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341824,
                      "name": "绩溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341825,
                      "name": "旌德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341881,
                      "name": "宁国市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341882,
                      "name": "广德市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xuancheng",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221400,
              "name": "六安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341502,
                      "name": "金安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341503,
                      "name": "裕安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341504,
                      "name": "叶集区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341522,
                      "name": "霍邱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341523,
                      "name": "舒城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341524,
                      "name": "金寨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341525,
                      "name": "霍山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liuan",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221500,
              "name": "池州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341702,
                      "name": "贵池区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341721,
                      "name": "东至县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341722,
                      "name": "石台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341723,
                      "name": "青阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chizhou",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221600,
              "name": "黄山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 341002,
                      "name": "屯溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341003,
                      "name": "黄山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341004,
                      "name": "徽州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341021,
                      "name": "歙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341022,
                      "name": "休宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341023,
                      "name": "黟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 341024,
                      "name": "祁门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huangshan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "a",
      "pinyin": null,
      "rank": 12,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101230000,
      "name": "福建",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101230100,
              "name": "福州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350102,
                      "name": "鼓楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350103,
                      "name": "台江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350104,
                      "name": "仓山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350105,
                      "name": "马尾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350111,
                      "name": "晋安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350112,
                      "name": "长乐区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350121,
                      "name": "闽侯县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350122,
                      "name": "连江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350123,
                      "name": "罗源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350124,
                      "name": "闽清县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350125,
                      "name": "永泰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350128,
                      "name": "平潭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350181,
                      "name": "福清市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fuzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230200,
              "name": "厦门",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350203,
                      "name": "思明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350205,
                      "name": "海沧区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350206,
                      "name": "湖里区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350211,
                      "name": "集美区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350212,
                      "name": "同安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350213,
                      "name": "翔安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xiamen",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230300,
              "name": "宁德",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350902,
                      "name": "蕉城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350921,
                      "name": "霞浦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350922,
                      "name": "古田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350923,
                      "name": "屏南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350924,
                      "name": "寿宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350925,
                      "name": "周宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350926,
                      "name": "柘荣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350981,
                      "name": "福安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350982,
                      "name": "福鼎市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "ningde",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230400,
              "name": "莆田",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350302,
                      "name": "城厢区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350303,
                      "name": "涵江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350304,
                      "name": "荔城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350305,
                      "name": "秀屿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350322,
                      "name": "仙游县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "putian",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230500,
              "name": "泉州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350502,
                      "name": "鲤城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350503,
                      "name": "丰泽区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350504,
                      "name": "洛江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350505,
                      "name": "泉港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350521,
                      "name": "惠安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350524,
                      "name": "安溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350525,
                      "name": "永春县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350526,
                      "name": "德化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350527,
                      "name": "金门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350581,
                      "name": "石狮市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350582,
                      "name": "晋江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350583,
                      "name": "南安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "quanzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230600,
              "name": "漳州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350602,
                      "name": "芗城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350603,
                      "name": "龙文区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350622,
                      "name": "云霄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350623,
                      "name": "漳浦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350624,
                      "name": "诏安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350625,
                      "name": "长泰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350626,
                      "name": "东山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350627,
                      "name": "南靖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350628,
                      "name": "平和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350629,
                      "name": "华安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350681,
                      "name": "龙海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhangzhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230700,
              "name": "龙岩",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350802,
                      "name": "新罗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350803,
                      "name": "永定区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350821,
                      "name": "长汀县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350823,
                      "name": "上杭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350824,
                      "name": "武平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350825,
                      "name": "连城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350881,
                      "name": "漳平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "longyan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230800,
              "name": "三明",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350403,
                      "name": "三元区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350421,
                      "name": "明溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350423,
                      "name": "清流县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350424,
                      "name": "宁化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350425,
                      "name": "大田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350426,
                      "name": "尤溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350427,
                      "name": "沙县区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350428,
                      "name": "将乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350429,
                      "name": "泰宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350430,
                      "name": "建宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350481,
                      "name": "永安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "sanming",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230900,
              "name": "南平",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 350702,
                      "name": "延平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350703,
                      "name": "建阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350721,
                      "name": "顺昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350722,
                      "name": "浦城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350723,
                      "name": "光泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350724,
                      "name": "松溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350725,
                      "name": "政和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350781,
                      "name": "邵武市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350782,
                      "name": "武夷山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 350783,
                      "name": "建瓯市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanping",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "f",
      "pinyin": null,
      "rank": 13,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101240000,
      "name": "江西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101240100,
              "name": "南昌",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360102,
                      "name": "东湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360103,
                      "name": "西湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360104,
                      "name": "青云谱区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360111,
                      "name": "青山湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360112,
                      "name": "新建区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360121,
                      "name": "南昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360123,
                      "name": "安义县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360124,
                      "name": "进贤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360125,
                      "name": "红谷滩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanchang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240200,
              "name": "九江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360402,
                      "name": "濂溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360403,
                      "name": "浔阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360404,
                      "name": "柴桑区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360423,
                      "name": "武宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360424,
                      "name": "修水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360425,
                      "name": "永修县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360426,
                      "name": "德安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360428,
                      "name": "都昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360429,
                      "name": "湖口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360430,
                      "name": "彭泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360481,
                      "name": "瑞昌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360482,
                      "name": "共青城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360483,
                      "name": "庐山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiujiang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240300,
              "name": "上饶",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 361102,
                      "name": "信州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361103,
                      "name": "广丰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361104,
                      "name": "广信区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361123,
                      "name": "玉山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361124,
                      "name": "铅山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361125,
                      "name": "横峰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361126,
                      "name": "弋阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361127,
                      "name": "余干县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361128,
                      "name": "鄱阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361129,
                      "name": "万年县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361130,
                      "name": "婺源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361181,
                      "name": "德兴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shangrao",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240400,
              "name": "抚州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 361002,
                      "name": "临川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361003,
                      "name": "东乡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361021,
                      "name": "南城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361022,
                      "name": "黎川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361023,
                      "name": "南丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361024,
                      "name": "崇仁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361025,
                      "name": "乐安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361026,
                      "name": "宜黄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361027,
                      "name": "金溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361028,
                      "name": "资溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 361030,
                      "name": "广昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fuzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240500,
              "name": "宜春",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360902,
                      "name": "袁州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360921,
                      "name": "奉新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360922,
                      "name": "万载县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360923,
                      "name": "上高县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360924,
                      "name": "宜丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360925,
                      "name": "靖安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360926,
                      "name": "铜鼓县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360981,
                      "name": "丰城市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360982,
                      "name": "樟树市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360983,
                      "name": "高安市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yichun",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240600,
              "name": "吉安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360802,
                      "name": "吉州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360803,
                      "name": "青原区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360821,
                      "name": "吉安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360822,
                      "name": "吉水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360823,
                      "name": "峡江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360824,
                      "name": "新干县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360825,
                      "name": "永丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360826,
                      "name": "泰和县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360827,
                      "name": "遂川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360828,
                      "name": "万安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360829,
                      "name": "安福县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360830,
                      "name": "永新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360881,
                      "name": "井冈山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jian",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240700,
              "name": "赣州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360702,
                      "name": "章贡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360703,
                      "name": "南康区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360704,
                      "name": "赣县区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360722,
                      "name": "信丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360723,
                      "name": "大余县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360724,
                      "name": "上犹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360725,
                      "name": "崇义县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360726,
                      "name": "安远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360727,
                      "name": "龙南市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360728,
                      "name": "定南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360729,
                      "name": "全南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360730,
                      "name": "宁都县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360731,
                      "name": "于都县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360732,
                      "name": "兴国县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360733,
                      "name": "会昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360734,
                      "name": "寻乌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360735,
                      "name": "石城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360781,
                      "name": "瑞金市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "ganzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240800,
              "name": "景德镇",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360202,
                      "name": "昌江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360203,
                      "name": "珠山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360222,
                      "name": "浮梁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360281,
                      "name": "乐平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jingdezhen",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240900,
              "name": "萍乡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360302,
                      "name": "安源区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360313,
                      "name": "湘东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360321,
                      "name": "莲花县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360322,
                      "name": "上栗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360323,
                      "name": "芦溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "pingxiang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101241000,
              "name": "新余",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360502,
                      "name": "渝水区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360521,
                      "name": "分宜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xinyu",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101241100,
              "name": "鹰潭",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 360602,
                      "name": "月湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360603,
                      "name": "余江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 360681,
                      "name": "贵溪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yingtan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 14,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101250000,
      "name": "湖南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101250100,
              "name": "长沙",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430102,
                      "name": "芙蓉区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430103,
                      "name": "天心区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430104,
                      "name": "岳麓区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430105,
                      "name": "开福区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430111,
                      "name": "雨花区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430112,
                      "name": "望城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430121,
                      "name": "长沙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430181,
                      "name": "浏阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430182,
                      "name": "宁乡市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changsha",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250200,
              "name": "湘潭",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430302,
                      "name": "雨湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430304,
                      "name": "岳塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430321,
                      "name": "湘潭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430381,
                      "name": "湘乡市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430382,
                      "name": "韶山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xiangtan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250300,
              "name": "株洲",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430202,
                      "name": "荷塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430203,
                      "name": "芦淞区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430204,
                      "name": "石峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430211,
                      "name": "天元区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430212,
                      "name": "渌口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430223,
                      "name": "攸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430224,
                      "name": "茶陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430225,
                      "name": "炎陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430281,
                      "name": "醴陵市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhuzhou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250400,
              "name": "衡阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430405,
                      "name": "珠晖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430406,
                      "name": "雁峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430407,
                      "name": "石鼓区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430408,
                      "name": "蒸湘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430412,
                      "name": "南岳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430421,
                      "name": "衡阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430422,
                      "name": "衡南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430423,
                      "name": "衡山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430424,
                      "name": "衡东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430426,
                      "name": "祁东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430481,
                      "name": "耒阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430482,
                      "name": "常宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hengyang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250500,
              "name": "郴州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 431002,
                      "name": "北湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431003,
                      "name": "苏仙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431021,
                      "name": "桂阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431022,
                      "name": "宜章县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431023,
                      "name": "永兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431024,
                      "name": "嘉禾县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431025,
                      "name": "临武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431026,
                      "name": "汝城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431027,
                      "name": "桂东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431028,
                      "name": "安仁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431081,
                      "name": "资兴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "z",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chenzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250600,
              "name": "常德",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430702,
                      "name": "武陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430703,
                      "name": "鼎城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430721,
                      "name": "安乡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430722,
                      "name": "汉寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430723,
                      "name": "澧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430724,
                      "name": "临澧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430725,
                      "name": "桃源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430726,
                      "name": "石门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430781,
                      "name": "津市市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "changde",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250700,
              "name": "益阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430902,
                      "name": "资阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430903,
                      "name": "赫山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430921,
                      "name": "南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430922,
                      "name": "桃江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430923,
                      "name": "安化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430981,
                      "name": "沅江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yiyang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250800,
              "name": "娄底",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 431302,
                      "name": "娄星区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431321,
                      "name": "双峰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431322,
                      "name": "新化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431381,
                      "name": "冷水江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431382,
                      "name": "涟源市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "loudi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250900,
              "name": "邵阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430502,
                      "name": "双清区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430503,
                      "name": "大祥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430511,
                      "name": "北塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430522,
                      "name": "新邵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430523,
                      "name": "邵阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430524,
                      "name": "隆回县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430525,
                      "name": "洞口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430527,
                      "name": "绥宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430528,
                      "name": "新宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430529,
                      "name": "城步苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430581,
                      "name": "武冈市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430582,
                      "name": "邵东市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shaoyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251000,
              "name": "岳阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430602,
                      "name": "岳阳楼区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430603,
                      "name": "云溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430611,
                      "name": "君山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430621,
                      "name": "岳阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430623,
                      "name": "华容县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430624,
                      "name": "湘阴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430626,
                      "name": "平江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430681,
                      "name": "汨罗市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430682,
                      "name": "临湘市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yueyang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251100,
              "name": "张家界",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 430802,
                      "name": "永定区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430811,
                      "name": "武陵源区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430821,
                      "name": "慈利县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 430822,
                      "name": "桑植县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhangjiajie",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251200,
              "name": "怀化",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 431202,
                      "name": "鹤城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431221,
                      "name": "中方县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431222,
                      "name": "沅陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431223,
                      "name": "辰溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431224,
                      "name": "溆浦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431225,
                      "name": "会同县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431226,
                      "name": "麻阳苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431227,
                      "name": "新晃侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431228,
                      "name": "芷江侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431229,
                      "name": "靖州苗族侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431230,
                      "name": "通道侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431281,
                      "name": "洪江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huaihua",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251300,
              "name": "永州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 431102,
                      "name": "零陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431103,
                      "name": "冷水滩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431121,
                      "name": "祁阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431122,
                      "name": "东安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431123,
                      "name": "双牌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431124,
                      "name": "道县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431125,
                      "name": "江永县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431126,
                      "name": "宁远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431127,
                      "name": "蓝山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431128,
                      "name": "新田县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 431129,
                      "name": "江华瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yongzhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251400,
              "name": "湘西土家族苗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 433101,
                      "name": "吉首市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433122,
                      "name": "泸溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433123,
                      "name": "凤凰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433124,
                      "name": "花垣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433125,
                      "name": "保靖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433126,
                      "name": "古丈县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433127,
                      "name": "永顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 433130,
                      "name": "龙山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xiangxi",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 18,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101260000,
      "name": "贵州",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101260100,
              "name": "贵阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520102,
                      "name": "南明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520103,
                      "name": "云岩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520111,
                      "name": "花溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520112,
                      "name": "乌当区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520113,
                      "name": "白云区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520115,
                      "name": "观山湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520121,
                      "name": "开阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520122,
                      "name": "息烽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520123,
                      "name": "修文县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520181,
                      "name": "清镇市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guiyang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260200,
              "name": "遵义",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520302,
                      "name": "红花岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520303,
                      "name": "汇川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520304,
                      "name": "播州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520322,
                      "name": "桐梓县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520323,
                      "name": "绥阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520324,
                      "name": "正安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520325,
                      "name": "道真仡佬族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520326,
                      "name": "务川仡佬族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520327,
                      "name": "凤冈县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520328,
                      "name": "湄潭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520329,
                      "name": "余庆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520330,
                      "name": "习水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520381,
                      "name": "赤水市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520382,
                      "name": "仁怀市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zunyi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260300,
              "name": "安顺",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520402,
                      "name": "西秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520403,
                      "name": "平坝区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520422,
                      "name": "普定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520423,
                      "name": "镇宁布依族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520424,
                      "name": "关岭布依族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520425,
                      "name": "紫云苗族布依族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "anshun",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260400,
              "name": "铜仁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520602,
                      "name": "碧江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520603,
                      "name": "万山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520621,
                      "name": "江口县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520622,
                      "name": "玉屏侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520623,
                      "name": "石阡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520624,
                      "name": "思南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520625,
                      "name": "印江土家族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520626,
                      "name": "德江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520627,
                      "name": "沿河土家族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520628,
                      "name": "松桃苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "t",
              "pinyin": "tongren",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260500,
              "name": "毕节",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520502,
                      "name": "七星关区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520521,
                      "name": "大方县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520522,
                      "name": "黔西市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520523,
                      "name": "金沙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520524,
                      "name": "织金县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520525,
                      "name": "纳雍县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520526,
                      "name": "威宁彝族回族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520527,
                      "name": "赫章县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bijie",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260600,
              "name": "六盘水",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 520201,
                      "name": "钟山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520203,
                      "name": "六枝特区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520221,
                      "name": "水城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 520281,
                      "name": "盘州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liupanshui",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260700,
              "name": "黔东南苗族侗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 522601,
                      "name": "凯里市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522622,
                      "name": "黄平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522623,
                      "name": "施秉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522624,
                      "name": "三穗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522625,
                      "name": "镇远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522626,
                      "name": "岑巩县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522627,
                      "name": "天柱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522628,
                      "name": "锦屏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522629,
                      "name": "剑河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522630,
                      "name": "台江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522631,
                      "name": "黎平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522632,
                      "name": "榕江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522633,
                      "name": "从江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522634,
                      "name": "雷山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522635,
                      "name": "麻江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522636,
                      "name": "丹寨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qiandongnan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260800,
              "name": "黔南布依族苗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 522701,
                      "name": "都匀市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522702,
                      "name": "福泉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "f",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522722,
                      "name": "荔波县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522723,
                      "name": "贵定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522725,
                      "name": "瓮安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522726,
                      "name": "独山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522727,
                      "name": "平塘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522728,
                      "name": "罗甸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522729,
                      "name": "长顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522730,
                      "name": "龙里县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522731,
                      "name": "惠水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522732,
                      "name": "三都水族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qiannan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260900,
              "name": "黔西南布依族苗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 522301,
                      "name": "兴义市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522302,
                      "name": "兴仁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522323,
                      "name": "普安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522324,
                      "name": "晴隆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522325,
                      "name": "贞丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522326,
                      "name": "望谟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522327,
                      "name": "册亨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 522328,
                      "name": "安龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qianxinan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 24,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101270000,
      "name": "四川",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101270100,
              "name": "成都",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510104,
                      "name": "锦江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510105,
                      "name": "青羊区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510106,
                      "name": "金牛区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510107,
                      "name": "武侯区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510108,
                      "name": "成华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510112,
                      "name": "龙泉驿区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510113,
                      "name": "青白江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510114,
                      "name": "新都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510115,
                      "name": "温江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510116,
                      "name": "双流区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510117,
                      "name": "郫都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510121,
                      "name": "金堂县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510129,
                      "name": "大邑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510131,
                      "name": "蒲江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510132,
                      "name": "新津区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510181,
                      "name": "都江堰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510182,
                      "name": "彭州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510183,
                      "name": "邛崃市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "q",
                      "pinyin": null,
                      "rank": 19,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510184,
                      "name": "崇州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 20,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510185,
                      "name": "简阳市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chengdu",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270200,
              "name": "攀枝花",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510402,
                      "name": "东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510403,
                      "name": "西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510411,
                      "name": "仁和区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510421,
                      "name": "米易县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510422,
                      "name": "盐边县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "panzhihua",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270300,
              "name": "自贡",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510302,
                      "name": "自流井区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510303,
                      "name": "贡井区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510304,
                      "name": "大安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510311,
                      "name": "沿滩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510321,
                      "name": "荣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510322,
                      "name": "富顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zigong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270400,
              "name": "绵阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510703,
                      "name": "涪城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510704,
                      "name": "游仙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510705,
                      "name": "安州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510722,
                      "name": "三台县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510723,
                      "name": "盐亭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510725,
                      "name": "梓潼县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510726,
                      "name": "北川羌族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510727,
                      "name": "平武县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510781,
                      "name": "江油市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "mianyang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270500,
              "name": "南充",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511302,
                      "name": "顺庆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511303,
                      "name": "高坪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511304,
                      "name": "嘉陵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511321,
                      "name": "南部县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511322,
                      "name": "营山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511323,
                      "name": "蓬安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511324,
                      "name": "仪陇县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511325,
                      "name": "西充县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511381,
                      "name": "阆中市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanchong",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270600,
              "name": "达州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511702,
                      "name": "通川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511703,
                      "name": "达川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511722,
                      "name": "宣汉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511723,
                      "name": "开江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511724,
                      "name": "大竹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511725,
                      "name": "渠县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511781,
                      "name": "万源市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dazhou",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270700,
              "name": "遂宁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510903,
                      "name": "船山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510904,
                      "name": "安居区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510921,
                      "name": "蓬溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510923,
                      "name": "大英县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510981,
                      "name": "射洪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "suining",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270800,
              "name": "广安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511602,
                      "name": "广安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511603,
                      "name": "前锋区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511621,
                      "name": "岳池县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511622,
                      "name": "武胜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511623,
                      "name": "邻水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511681,
                      "name": "华蓥市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guangan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270900,
              "name": "巴中",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511902,
                      "name": "巴州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511903,
                      "name": "恩阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511921,
                      "name": "通江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511922,
                      "name": "南江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511923,
                      "name": "平昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "bazhong",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271000,
              "name": "泸州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510502,
                      "name": "江阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510503,
                      "name": "纳溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510504,
                      "name": "龙马潭区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510521,
                      "name": "泸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510522,
                      "name": "合江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510524,
                      "name": "叙永县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510525,
                      "name": "古蔺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "luzhou",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271100,
              "name": "宜宾",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511502,
                      "name": "翠屏区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511503,
                      "name": "南溪区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511504,
                      "name": "叙州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511523,
                      "name": "江安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511524,
                      "name": "长宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511525,
                      "name": "高县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511526,
                      "name": "珙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511527,
                      "name": "筠连县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511528,
                      "name": "兴文县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511529,
                      "name": "屏山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yibin",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271200,
              "name": "内江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511002,
                      "name": "市中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511011,
                      "name": "东兴区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511024,
                      "name": "威远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511025,
                      "name": "资中县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511083,
                      "name": "隆昌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "neijiang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271300,
              "name": "资阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 512002,
                      "name": "雁江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 512021,
                      "name": "安岳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 512022,
                      "name": "乐至县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "ziyang",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271400,
              "name": "乐山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511102,
                      "name": "市中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511111,
                      "name": "沙湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511112,
                      "name": "五通桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511113,
                      "name": "金口河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511123,
                      "name": "犍为县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511124,
                      "name": "井研县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511126,
                      "name": "夹江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511129,
                      "name": "沐川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511132,
                      "name": "峨边彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511133,
                      "name": "马边彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511181,
                      "name": "峨眉山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "e",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "leshan",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271500,
              "name": "眉山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511402,
                      "name": "东坡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511403,
                      "name": "彭山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511421,
                      "name": "仁寿县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511423,
                      "name": "洪雅县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511424,
                      "name": "丹棱县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511425,
                      "name": "青神县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "meishan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271600,
              "name": "雅安",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 511802,
                      "name": "雨城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511803,
                      "name": "名山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511822,
                      "name": "荥经县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511823,
                      "name": "汉源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511824,
                      "name": "石棉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511825,
                      "name": "天全县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511826,
                      "name": "芦山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 511827,
                      "name": "宝兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yaan",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271700,
              "name": "德阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510603,
                      "name": "旌阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510604,
                      "name": "罗江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510623,
                      "name": "中江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510681,
                      "name": "广汉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510682,
                      "name": "什邡市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510683,
                      "name": "绵竹市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "deyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271800,
              "name": "广元",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 510802,
                      "name": "利州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510811,
                      "name": "昭化区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510812,
                      "name": "朝天区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510821,
                      "name": "旺苍县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510822,
                      "name": "青川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510823,
                      "name": "剑阁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 510824,
                      "name": "苍溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guangyuan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271900,
              "name": "阿坝藏族羌族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 513201,
                      "name": "马尔康市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513221,
                      "name": "汶川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513222,
                      "name": "理县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513223,
                      "name": "茂县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513224,
                      "name": "松潘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513225,
                      "name": "九寨沟县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513226,
                      "name": "金川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513227,
                      "name": "小金县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513228,
                      "name": "黑水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513230,
                      "name": "壤塘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513231,
                      "name": "阿坝县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513232,
                      "name": "若尔盖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513233,
                      "name": "红原县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "aba",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101272000,
              "name": "凉山彝族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 513401,
                      "name": "西昌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513422,
                      "name": "木里藏族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513423,
                      "name": "盐源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513424,
                      "name": "德昌县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513425,
                      "name": "会理市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513426,
                      "name": "会东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513427,
                      "name": "宁南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513428,
                      "name": "普格县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513429,
                      "name": "布拖县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513430,
                      "name": "金阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513431,
                      "name": "昭觉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513432,
                      "name": "喜德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513433,
                      "name": "冕宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513434,
                      "name": "越西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513435,
                      "name": "甘洛县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513436,
                      "name": "美姑县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513437,
                      "name": "雷波县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liangshan",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101272100,
              "name": "甘孜藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 513301,
                      "name": "康定市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513322,
                      "name": "泸定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513323,
                      "name": "丹巴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513324,
                      "name": "九龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513325,
                      "name": "雅江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513326,
                      "name": "道孚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513327,
                      "name": "炉霍县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513328,
                      "name": "甘孜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513329,
                      "name": "新龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513330,
                      "name": "德格县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513331,
                      "name": "白玉县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513332,
                      "name": "石渠县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513333,
                      "name": "色达县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513334,
                      "name": "理塘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513335,
                      "name": "巴塘县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513336,
                      "name": "乡城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513337,
                      "name": "稻城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 513338,
                      "name": "得荣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 18,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "ganzi",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 23,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101280000,
      "name": "广东",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101280100,
              "name": "广州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440103,
                      "name": "荔湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440104,
                      "name": "越秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440105,
                      "name": "海珠区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440106,
                      "name": "天河区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440111,
                      "name": "白云区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440112,
                      "name": "黄埔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440113,
                      "name": "番禺区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440114,
                      "name": "花都区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440115,
                      "name": "南沙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440117,
                      "name": "从化区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440118,
                      "name": "增城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guangzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280200,
              "name": "韶关",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440203,
                      "name": "武江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440204,
                      "name": "浈江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440205,
                      "name": "曲江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440222,
                      "name": "始兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440224,
                      "name": "仁化县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440229,
                      "name": "翁源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440232,
                      "name": "乳源瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440233,
                      "name": "新丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440281,
                      "name": "乐昌市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440282,
                      "name": "南雄市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shaoguan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280300,
              "name": "惠州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441302,
                      "name": "惠城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441303,
                      "name": "惠阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441322,
                      "name": "博罗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441323,
                      "name": "惠东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441324,
                      "name": "龙门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "huizhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280400,
              "name": "梅州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441402,
                      "name": "梅江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441403,
                      "name": "梅县区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441422,
                      "name": "大埔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441423,
                      "name": "丰顺县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441424,
                      "name": "五华县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441426,
                      "name": "平远县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441427,
                      "name": "蕉岭县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441481,
                      "name": "兴宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "meizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280500,
              "name": "汕头",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440507,
                      "name": "龙湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440511,
                      "name": "金平区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440512,
                      "name": "濠江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440513,
                      "name": "潮阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440514,
                      "name": "潮南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440515,
                      "name": "澄海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440523,
                      "name": "南澳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shantou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280600,
              "name": "深圳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440303,
                      "name": "罗湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440304,
                      "name": "福田区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440305,
                      "name": "南山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440306,
                      "name": "宝安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440307,
                      "name": "龙岗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440308,
                      "name": "盐田区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440309,
                      "name": "龙华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440310,
                      "name": "坪山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440311,
                      "name": "光明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 0,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shenzhen",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280700,
              "name": "珠海",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440402,
                      "name": "香洲区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440403,
                      "name": "斗门区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440404,
                      "name": "金湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhuhai",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280800,
              "name": "佛山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440604,
                      "name": "禅城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440605,
                      "name": "南海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440606,
                      "name": "顺德区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440607,
                      "name": "三水区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440608,
                      "name": "高明区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "foshan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280900,
              "name": "肇庆",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441202,
                      "name": "端州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441203,
                      "name": "鼎湖区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441204,
                      "name": "高要区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441223,
                      "name": "广宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441224,
                      "name": "怀集县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441225,
                      "name": "封开县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441226,
                      "name": "德庆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441284,
                      "name": "四会市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "s",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhaoqing",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281000,
              "name": "湛江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440802,
                      "name": "赤坎区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440803,
                      "name": "霞山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440804,
                      "name": "坡头区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440811,
                      "name": "麻章区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440823,
                      "name": "遂溪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440825,
                      "name": "徐闻县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440881,
                      "name": "廉江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440882,
                      "name": "雷州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440883,
                      "name": "吴川市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhanjiang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281100,
              "name": "江门",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440703,
                      "name": "蓬江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440704,
                      "name": "江海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440705,
                      "name": "新会区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440781,
                      "name": "台山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440783,
                      "name": "开平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440784,
                      "name": "鹤山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440785,
                      "name": "恩平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "e",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jiangmen",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281200,
              "name": "河源",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441602,
                      "name": "源城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441621,
                      "name": "紫金县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441622,
                      "name": "龙川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441623,
                      "name": "连平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441624,
                      "name": "和平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441625,
                      "name": "东源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "heyuan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281300,
              "name": "清远",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441802,
                      "name": "清城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441803,
                      "name": "清新区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441821,
                      "name": "佛冈县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441823,
                      "name": "阳山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441825,
                      "name": "连山壮族瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441826,
                      "name": "连南瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441881,
                      "name": "英德市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441882,
                      "name": "连州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qingyuan",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281400,
              "name": "云浮",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 445302,
                      "name": "云城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445303,
                      "name": "云安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445321,
                      "name": "新兴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445322,
                      "name": "郁南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445381,
                      "name": "罗定市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yunfu",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281500,
              "name": "潮州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 445102,
                      "name": "湘桥区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445103,
                      "name": "潮安区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445122,
                      "name": "饶平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chaozhou",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281600,
              "name": "东莞",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441900003,
                      "name": "东城街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900004,
                      "name": "南城街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900005,
                      "name": "万江街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900006,
                      "name": "莞城街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900101,
                      "name": "石碣镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900102,
                      "name": "石龙镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900103,
                      "name": "茶山镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900104,
                      "name": "石排镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900105,
                      "name": "企石镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900106,
                      "name": "横沥镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900107,
                      "name": "桥头镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900108,
                      "name": "谢岗镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900109,
                      "name": "东坑镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900110,
                      "name": "常平镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900111,
                      "name": "寮步镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900112,
                      "name": "樟木头镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900113,
                      "name": "大朗镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900114,
                      "name": "黄江镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900115,
                      "name": "清溪镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900116,
                      "name": "塘厦镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900117,
                      "name": "凤岗镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900118,
                      "name": "大岭山镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900119,
                      "name": "长安镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900121,
                      "name": "虎门镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900122,
                      "name": "厚街镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900123,
                      "name": "沙田镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900124,
                      "name": "道滘镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900125,
                      "name": "洪梅镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900126,
                      "name": "麻涌镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900127,
                      "name": "望牛墩镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900128,
                      "name": "中堂镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441900129,
                      "name": "高埗镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dongguan",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281700,
              "name": "中山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 442000001,
                      "name": "石岐街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000002,
                      "name": "东区街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000003,
                      "name": "火炬开发区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000004,
                      "name": "西区街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000005,
                      "name": "南区街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000006,
                      "name": "五桂山街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000100,
                      "name": "小榄镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000101,
                      "name": "黄圃镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000102,
                      "name": "民众街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000103,
                      "name": "东凤镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000104,
                      "name": "东升镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000105,
                      "name": "古镇镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000106,
                      "name": "沙溪镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000107,
                      "name": "坦洲镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000108,
                      "name": "港口镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000109,
                      "name": "三角镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000110,
                      "name": "横栏镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000111,
                      "name": "南头镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000112,
                      "name": "阜沙镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000113,
                      "name": "南朗街道",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "n",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000114,
                      "name": "三乡镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000115,
                      "name": "板芙镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000116,
                      "name": "大涌镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 442000117,
                      "name": "神湾镇",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhongshan",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281800,
              "name": "阳江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441702,
                      "name": "江城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441704,
                      "name": "阳东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441721,
                      "name": "阳西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441781,
                      "name": "阳春市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "y",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yangjiang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281900,
              "name": "揭阳",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 445202,
                      "name": "榕城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445203,
                      "name": "揭东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445222,
                      "name": "揭西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445224,
                      "name": "惠来县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 445281,
                      "name": "普宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "j",
              "pinyin": "jieyang",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282000,
              "name": "茂名",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 440902,
                      "name": "茂南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440904,
                      "name": "电白区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440981,
                      "name": "高州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440982,
                      "name": "化州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 440983,
                      "name": "信宜市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "m",
              "pinyin": "maoming",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282100,
              "name": "汕尾",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 441502,
                      "name": "城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441521,
                      "name": "海丰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441523,
                      "name": "陆河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 441581,
                      "name": "陆丰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "shanwei",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282200,
              "name": "东沙群岛",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "d",
              "pinyin": "dongshaqundao",
              "rank": 22,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 19,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101290000,
      "name": "云南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101290100,
              "name": "昆明",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530102,
                      "name": "五华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530103,
                      "name": "盘龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530111,
                      "name": "官渡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530112,
                      "name": "西山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530113,
                      "name": "东川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530114,
                      "name": "呈贡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530115,
                      "name": "晋宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530124,
                      "name": "富民县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530125,
                      "name": "宜良县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530126,
                      "name": "石林彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530127,
                      "name": "嵩明县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530128,
                      "name": "禄劝彝族苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530129,
                      "name": "寻甸回族彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530181,
                      "name": "安宁市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "a",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "k",
              "pinyin": "kunming",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290200,
              "name": "曲靖",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530302,
                      "name": "麒麟区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530303,
                      "name": "沾益区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530304,
                      "name": "马龙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530322,
                      "name": "陆良县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530323,
                      "name": "师宗县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530324,
                      "name": "罗平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530325,
                      "name": "富源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530326,
                      "name": "会泽县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530381,
                      "name": "宣威市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qujing",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290300,
              "name": "保山",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530502,
                      "name": "隆阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530521,
                      "name": "施甸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530523,
                      "name": "龙陵县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530524,
                      "name": "昌宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530581,
                      "name": "腾冲市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "t",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baoshan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290400,
              "name": "玉溪",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530402,
                      "name": "红塔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530403,
                      "name": "江川区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530423,
                      "name": "通海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530424,
                      "name": "华宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530425,
                      "name": "易门县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530426,
                      "name": "峨山彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530427,
                      "name": "新平彝族傣族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530428,
                      "name": "元江哈尼族彝族傣族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530481,
                      "name": "澄江市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yuxi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290500,
              "name": "普洱",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530802,
                      "name": "思茅区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530821,
                      "name": "宁洱哈尼族彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530822,
                      "name": "墨江哈尼族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530823,
                      "name": "景东彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530824,
                      "name": "景谷傣族彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530825,
                      "name": "镇沅彝族哈尼族拉祜族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530826,
                      "name": "江城哈尼族彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530827,
                      "name": "孟连傣族拉祜族佤族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530828,
                      "name": "澜沧拉祜族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530829,
                      "name": "西盟佤族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "p",
              "pinyin": "puer",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290700,
              "name": "昭通",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530602,
                      "name": "昭阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530621,
                      "name": "鲁甸县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530622,
                      "name": "巧家县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530623,
                      "name": "盐津县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530624,
                      "name": "大关县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530625,
                      "name": "永善县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530626,
                      "name": "绥江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530627,
                      "name": "镇雄县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530628,
                      "name": "彝良县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530629,
                      "name": "威信县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530681,
                      "name": "水富市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "z",
              "pinyin": "zhaotong",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290800,
              "name": "临沧",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530902,
                      "name": "临翔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530921,
                      "name": "凤庆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530922,
                      "name": "云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530923,
                      "name": "永德县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530924,
                      "name": "镇康县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530925,
                      "name": "双江拉祜族佤族布朗族傣族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530926,
                      "name": "耿马傣族佤族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530927,
                      "name": "沧源佤族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lincang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290900,
              "name": "丽江",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 530702,
                      "name": "古城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530721,
                      "name": "玉龙纳西族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530722,
                      "name": "永胜县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530723,
                      "name": "华坪县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 530724,
                      "name": "宁蒗彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "lijiang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291000,
              "name": "西双版纳傣族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 532801,
                      "name": "景洪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532822,
                      "name": "勐海县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532823,
                      "name": "勐腊县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xishuangbanna",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291100,
              "name": "文山壮族苗族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 532601,
                      "name": "文山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "w",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532622,
                      "name": "砚山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532623,
                      "name": "西畴县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532624,
                      "name": "麻栗坡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532625,
                      "name": "马关县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532626,
                      "name": "丘北县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532627,
                      "name": "广南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532628,
                      "name": "富宁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wenshan",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291200,
              "name": "红河哈尼族彝族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 532501,
                      "name": "个旧市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532502,
                      "name": "开远市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "k",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532503,
                      "name": "蒙自市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532504,
                      "name": "弥勒市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532523,
                      "name": "屏边苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532524,
                      "name": "建水县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532525,
                      "name": "石屏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532527,
                      "name": "泸西县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532528,
                      "name": "元阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532529,
                      "name": "红河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532530,
                      "name": "金平苗族瑶族傣族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532531,
                      "name": "绿春县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532532,
                      "name": "河口瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "honghe",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291300,
              "name": "德宏傣族景颇族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 533102,
                      "name": "瑞丽市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "r",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533103,
                      "name": "芒市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "m",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533122,
                      "name": "梁河县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533123,
                      "name": "盈江县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533124,
                      "name": "陇川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dehong",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291400,
              "name": "怒江傈僳族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 533301,
                      "name": "泸水市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533323,
                      "name": "福贡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533324,
                      "name": "贡山独龙族怒族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533325,
                      "name": "兰坪白族普米族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nujiang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291500,
              "name": "迪庆藏族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 533401,
                      "name": "香格里拉市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "x",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533422,
                      "name": "德钦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 533423,
                      "name": "维西傈僳族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "diqing",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291600,
              "name": "大理白族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 532901,
                      "name": "大理市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532922,
                      "name": "漾濞彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532923,
                      "name": "祥云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532924,
                      "name": "宾川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532925,
                      "name": "弥渡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532926,
                      "name": "南涧彝族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532927,
                      "name": "巍山彝族回族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532928,
                      "name": "永平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532929,
                      "name": "云龙县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532930,
                      "name": "洱源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532931,
                      "name": "剑川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532932,
                      "name": "鹤庆县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "d",
              "pinyin": "dali",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291700,
              "name": "楚雄彝族自治州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 532301,
                      "name": "楚雄市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532322,
                      "name": "双柏县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532323,
                      "name": "牟定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532324,
                      "name": "南华县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532325,
                      "name": "姚安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532326,
                      "name": "大姚县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532327,
                      "name": "永仁县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532328,
                      "name": "元谋县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532329,
                      "name": "武定县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 532331,
                      "name": "禄丰市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chuxiong",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "y",
      "pinyin": null,
      "rank": 25,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101300000,
      "name": "广西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101300100,
              "name": "南宁",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450102,
                      "name": "兴宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450103,
                      "name": "青秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450105,
                      "name": "江南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450107,
                      "name": "西乡塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450108,
                      "name": "良庆区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450109,
                      "name": "邕宁区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450110,
                      "name": "武鸣区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450123,
                      "name": "隆安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450124,
                      "name": "马山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450125,
                      "name": "上林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450126,
                      "name": "宾阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450127,
                      "name": "横州市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "n",
              "pinyin": "nanning",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300200,
              "name": "崇左",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 451402,
                      "name": "江州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451421,
                      "name": "扶绥县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451422,
                      "name": "宁明县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451423,
                      "name": "龙州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451424,
                      "name": "大新县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451425,
                      "name": "天等县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451481,
                      "name": "凭祥市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "p",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "c",
              "pinyin": "chongzuo",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300300,
              "name": "柳州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450202,
                      "name": "城中区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450203,
                      "name": "鱼峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450204,
                      "name": "柳南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450205,
                      "name": "柳北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450206,
                      "name": "柳江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450222,
                      "name": "柳城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450223,
                      "name": "鹿寨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450224,
                      "name": "融安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450225,
                      "name": "融水苗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450226,
                      "name": "三江侗族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "liuzhou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300400,
              "name": "来宾",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 451302,
                      "name": "兴宾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451321,
                      "name": "忻城县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451322,
                      "name": "象州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451323,
                      "name": "武宣县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451324,
                      "name": "金秀瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451381,
                      "name": "合山市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "h",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "l",
              "pinyin": "laibin",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300500,
              "name": "桂林",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450302,
                      "name": "秀峰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450303,
                      "name": "叠彩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450304,
                      "name": "象山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450305,
                      "name": "七星区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450311,
                      "name": "雁山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450312,
                      "name": "临桂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450321,
                      "name": "阳朔县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450323,
                      "name": "灵川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450324,
                      "name": "全州县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450325,
                      "name": "兴安县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450326,
                      "name": "永福县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450327,
                      "name": "灌阳县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450328,
                      "name": "龙胜各族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 13,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450329,
                      "name": "资源县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 14,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450330,
                      "name": "平乐县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 15,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450332,
                      "name": "恭城瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 17,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450381,
                      "name": "荔浦市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "l",
                      "pinyin": null,
                      "rank": 16,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guilin",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300600,
              "name": "梧州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450403,
                      "name": "万秀区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450405,
                      "name": "长洲区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450406,
                      "name": "龙圩区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450421,
                      "name": "苍梧县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450422,
                      "name": "藤县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450423,
                      "name": "蒙山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450481,
                      "name": "岑溪市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "c",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "w",
              "pinyin": "wuzhou",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300700,
              "name": "贺州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 451102,
                      "name": "八步区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451103,
                      "name": "平桂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451121,
                      "name": "昭平县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451122,
                      "name": "钟山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451123,
                      "name": "富川瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hezhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300800,
              "name": "贵港",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450802,
                      "name": "港北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450803,
                      "name": "港南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450804,
                      "name": "覃塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450821,
                      "name": "平南县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450881,
                      "name": "桂平市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "g",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "g",
              "pinyin": "guigang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300900,
              "name": "玉林",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450902,
                      "name": "玉州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450903,
                      "name": "福绵区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450921,
                      "name": "容县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450922,
                      "name": "陆川县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450923,
                      "name": "博白县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450924,
                      "name": "兴业县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450981,
                      "name": "北流市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "b",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "y",
              "pinyin": "yulin",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301000,
              "name": "百色",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 451002,
                      "name": "右江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451003,
                      "name": "田阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451022,
                      "name": "田东县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451024,
                      "name": "德保县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451026,
                      "name": "那坡县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451027,
                      "name": "凌云县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451028,
                      "name": "乐业县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451029,
                      "name": "田林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451030,
                      "name": "西林县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451031,
                      "name": "隆林各族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451081,
                      "name": "靖西市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "j",
                      "pinyin": null,
                      "rank": 12,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451082,
                      "name": "平果市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "baise",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301100,
              "name": "钦州",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450702,
                      "name": "钦南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450703,
                      "name": "钦北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450721,
                      "name": "灵山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450722,
                      "name": "浦北县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "q",
              "pinyin": "qinzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301200,
              "name": "河池",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 451202,
                      "name": "金城江区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451203,
                      "name": "宜州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 11,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451221,
                      "name": "南丹县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451222,
                      "name": "天峨县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451223,
                      "name": "凤山县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451224,
                      "name": "东兰县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 5,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451225,
                      "name": "罗城仫佬族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 6,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451226,
                      "name": "环江毛南族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 7,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451227,
                      "name": "巴马瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 8,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451228,
                      "name": "都安瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 9,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 451229,
                      "name": "大化瑶族自治县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 10,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "hechi",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301300,
              "name": "北海",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450502,
                      "name": "海城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450503,
                      "name": "银海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450512,
                      "name": "铁山港区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450521,
                      "name": "合浦县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "b",
              "pinyin": "beihai",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301400,
              "name": "防城港",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 450602,
                      "name": "港口区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450603,
                      "name": "防城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450621,
                      "name": "上思县",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 450681,
                      "name": "东兴市",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "d",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 3,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "f",
              "pinyin": "fangchenggang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 20,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101310000,
      "name": "海南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101310100,
              "name": "海口",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 460105,
                      "name": "秀英区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460106,
                      "name": "龙华区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460107,
                      "name": "琼山区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460108,
                      "name": "美兰区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "h",
              "pinyin": "haikou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310200,
              "name": "三亚",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 460202,
                      "name": "海棠区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460203,
                      "name": "吉阳区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460204,
                      "name": "天涯区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460205,
                      "name": "崖州区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 4,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "sanya",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310300,
              "name": "三沙",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 460321,
                      "name": "西沙群岛",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 1,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460322,
                      "name": "南沙群岛",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 2,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 460323,
                      "name": "中沙群岛的岛礁及其海域",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 3,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 2,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "s",
              "pinyin": "sansha",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310400,
              "name": "儋州",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "d",
              "pinyin": "danzhou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310500,
              "name": "五指山",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "w",
              "pinyin": "wuzhishan",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310600,
              "name": "琼海",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "q",
              "pinyin": "qionghai",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310700,
              "name": "文昌",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "w",
              "pinyin": "wenchang",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310800,
              "name": "万宁",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "w",
              "pinyin": "wanning",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310900,
              "name": "东方",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "d",
              "pinyin": "dongfang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311000,
              "name": "定安",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "d",
              "pinyin": "dingan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311100,
              "name": "屯昌",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "t",
              "pinyin": "tunchang",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311200,
              "name": "澄迈",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "c",
              "pinyin": "chengmai",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311300,
              "name": "临高",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "l",
              "pinyin": "lingao",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311400,
              "name": "白沙黎族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "b",
              "pinyin": "baisha",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311500,
              "name": "昌江黎族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "c",
              "pinyin": "changjiang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311600,
              "name": "乐东黎族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "l",
              "pinyin": "ledong",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311700,
              "name": "陵水黎族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "l",
              "pinyin": "lingshui",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311800,
              "name": "保亭黎族苗族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "b",
              "pinyin": "baoting",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311900,
              "name": "琼中黎族苗族自治县",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "q",
              "pinyin": "qiongzhong",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 21,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101320000,
      "name": "香港",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101320300,
              "name": "香港",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 810001,
                      "name": "中西区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810002,
                      "name": "湾仔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810003,
                      "name": "东区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810004,
                      "name": "南区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810005,
                      "name": "油尖旺区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810006,
                      "name": "深水埗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810007,
                      "name": "九龙城区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810008,
                      "name": "黄大仙区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810009,
                      "name": "观塘区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810010,
                      "name": "荃湾区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810011,
                      "name": "屯门区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810012,
                      "name": "元朗区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810013,
                      "name": "北区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810014,
                      "name": "大埔区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810015,
                      "name": "西贡区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810016,
                      "name": "沙田区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810017,
                      "name": "葵青区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 810018,
                      "name": "离岛区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "x",
              "pinyin": "xianggang",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 33,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101330000,
      "name": "澳门",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101330100,
              "name": "澳门",
              "tip": null,
              "subLevelModelList": [
                  {
                      "code": 820001,
                      "name": "花地玛堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820002,
                      "name": "花王堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820003,
                      "name": "望德堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820004,
                      "name": "大堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820005,
                      "name": "风顺堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820006,
                      "name": "嘉模堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820007,
                      "name": "路凼填海区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  },
                  {
                      "code": 820008,
                      "name": "圣方济各堂区",
                      "tip": null,
                      "subLevelModelList": null,
                      "firstChar": "",
                      "pinyin": null,
                      "rank": 0,
                      "mark": 0,
                      "positionType": 0,
                      "cityType": 1,
                      "capital": 0,
                      "color": null,
                      "recruitmentType": null,
                      "cityCode": null,
                      "regionCode": 0
                  }
              ],
              "firstChar": "a",
              "pinyin": "aomen",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "a",
      "pinyin": null,
      "rank": 34,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101340000,
      "name": "台湾",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101341100,
              "name": "台湾",
              "tip": null,
              "subLevelModelList": null,
              "firstChar": "t",
              "pinyin": "taiwan",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "t",
      "pinyin": null,
      "rank": 32,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  }
]
export const provinceAndCityList  =  [
  {
      "code": 101010000,
      "name": "北京",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101010100,
              "name": "北京",
              "tip": null,
              "firstChar": "b",
              "pinyin": "beijing",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "b",
      "pinyin": null,
      "rank": 1,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101020000,
      "name": "上海",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101020100,
              "name": "上海",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shanghai",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 9,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101030000,
      "name": "天津",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101030100,
              "name": "天津",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tianjin",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "t",
      "pinyin": null,
      "rank": 2,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101040000,
      "name": "重庆",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101040100,
              "name": "重庆",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chongqing",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "c",
      "pinyin": null,
      "rank": 22,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101050000,
      "name": "黑龙江",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101050100,
              "name": "哈尔滨",
              "tip": null,
              "firstChar": "h",
              "pinyin": "haerbin",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050200,
              "name": "齐齐哈尔",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qiqihaer",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050300,
              "name": "牡丹江",
              "tip": null,
              "firstChar": "m",
              "pinyin": "mudanjiang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050400,
              "name": "佳木斯",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiamusi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050500,
              "name": "绥化",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suihua",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050600,
              "name": "黑河",
              "tip": null,
              "firstChar": "h",
              "pinyin": "heihe",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050700,
              "name": "伊春",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yichun",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050800,
              "name": "大庆",
              "tip": null,
              "firstChar": "d",
              "pinyin": "daqing",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101050900,
              "name": "七台河",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qitaihe",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051000,
              "name": "鸡西",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jixi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051100,
              "name": "鹤岗",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hegang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051200,
              "name": "双鸭山",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shuangyashan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101051300,
              "name": "大兴安岭地区",
              "tip": null,
              "firstChar": "d",
              "pinyin": "daxinganling",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 8,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101060000,
      "name": "吉林",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101060100,
              "name": "长春",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changchun",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060200,
              "name": "吉林",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jilin",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060300,
              "name": "四平",
              "tip": null,
              "firstChar": "s",
              "pinyin": "siping",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060400,
              "name": "通化",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tonghua",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060500,
              "name": "白城",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baicheng",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060600,
              "name": "辽源",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liaoyuan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060700,
              "name": "松原",
              "tip": null,
              "firstChar": "s",
              "pinyin": "songyuan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060800,
              "name": "白山",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baishan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101060900,
              "name": "延边朝鲜族自治州",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yanbian",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 7,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101070000,
      "name": "辽宁",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101070100,
              "name": "沈阳",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shenyang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070200,
              "name": "大连",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dalian",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070300,
              "name": "鞍山",
              "tip": null,
              "firstChar": "a",
              "pinyin": "anshan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070400,
              "name": "抚顺",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fushun",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070500,
              "name": "本溪",
              "tip": null,
              "firstChar": "b",
              "pinyin": "benxi",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070600,
              "name": "丹东",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dandong",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070700,
              "name": "锦州",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jinzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070800,
              "name": "营口",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yingkou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101070900,
              "name": "阜新",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fuxin",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071000,
              "name": "辽阳",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liaoyang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071100,
              "name": "铁岭",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tieling",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071200,
              "name": "朝阳",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chaoyang",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071300,
              "name": "盘锦",
              "tip": null,
              "firstChar": "p",
              "pinyin": "panjin",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101071400,
              "name": "葫芦岛",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huludao",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "l",
      "pinyin": null,
      "rank": 6,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101080000,
      "name": "内蒙古",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101080100,
              "name": "呼和浩特",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huhehaote",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080200,
              "name": "包头",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baotou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080300,
              "name": "乌海",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuhai",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080400,
              "name": "通辽",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tongliao",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080500,
              "name": "赤峰",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chifeng",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080600,
              "name": "鄂尔多斯",
              "tip": null,
              "firstChar": "e",
              "pinyin": "eerduosi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080700,
              "name": "呼伦贝尔",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hulunbeier",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080800,
              "name": "巴彦淖尔",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bayannaoer",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101080900,
              "name": "乌兰察布",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wulanchabu",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081000,
              "name": "锡林郭勒盟",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xilinguole",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081100,
              "name": "兴安盟",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinganmeng",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101081200,
              "name": "阿拉善盟",
              "tip": null,
              "firstChar": "a",
              "pinyin": "alashan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "n",
      "pinyin": null,
      "rank": 5,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101090000,
      "name": "河北",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101090100,
              "name": "石家庄",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shijiazhuang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090200,
              "name": "保定",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baoding",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090300,
              "name": "张家口",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhangjiakou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090400,
              "name": "承德",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chengde",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090500,
              "name": "唐山",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tangshan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090600,
              "name": "廊坊",
              "tip": null,
              "firstChar": "l",
              "pinyin": "langfang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090700,
              "name": "沧州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "cangzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090800,
              "name": "衡水",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hengshui",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101090900,
              "name": "邢台",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xingtai",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101091000,
              "name": "邯郸",
              "tip": null,
              "firstChar": "h",
              "pinyin": "handan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101091100,
              "name": "秦皇岛",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qinhuangdao",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 3,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101100000,
      "name": "山西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101100100,
              "name": "太原",
              "tip": null,
              "firstChar": "t",
              "pinyin": "taiyuan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100200,
              "name": "大同",
              "tip": null,
              "firstChar": "d",
              "pinyin": "datong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100300,
              "name": "阳泉",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yangquan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100400,
              "name": "晋中",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jinzhong",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100500,
              "name": "长治",
              "tip": null,
              "firstChar": "c",
              "pinyin": "zhangzhi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100600,
              "name": "晋城",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jincheng",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100700,
              "name": "临汾",
              "tip": null,
              "firstChar": "l",
              "pinyin": "linfen",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100800,
              "name": "运城",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yuncheng",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101100900,
              "name": "朔州",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shuozhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101101000,
              "name": "忻州",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101101100,
              "name": "吕梁",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lvliang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 4,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101110000,
      "name": "陕西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101110100,
              "name": "西安",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xian",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110200,
              "name": "咸阳",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xianyang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110300,
              "name": "延安",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yanan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110400,
              "name": "榆林",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yulin",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110500,
              "name": "渭南",
              "tip": null,
              "firstChar": "w",
              "pinyin": "weinan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110600,
              "name": "商洛",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shangluo",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110700,
              "name": "安康",
              "tip": null,
              "firstChar": "a",
              "pinyin": "ankang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110800,
              "name": "汉中",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hanzhong",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101110900,
              "name": "宝鸡",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baoji",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101111000,
              "name": "铜川",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tongchuan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 27,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101120000,
      "name": "山东",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101120100,
              "name": "济南",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jinan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120200,
              "name": "青岛",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qingdao",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120300,
              "name": "淄博",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zibo",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120400,
              "name": "德州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dezhou",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120500,
              "name": "烟台",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yantai",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120600,
              "name": "潍坊",
              "tip": null,
              "firstChar": "w",
              "pinyin": "weifang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120700,
              "name": "济宁",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jining",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120800,
              "name": "泰安",
              "tip": null,
              "firstChar": "t",
              "pinyin": "taian",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101120900,
              "name": "临沂",
              "tip": null,
              "firstChar": "l",
              "pinyin": "linyi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121000,
              "name": "菏泽",
              "tip": null,
              "firstChar": "h",
              "pinyin": "heze",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121100,
              "name": "滨州",
              "tip": null,
              "firstChar": "b",
              "pinyin": "binzhou",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121200,
              "name": "东营",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dongying",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121300,
              "name": "威海",
              "tip": null,
              "firstChar": "w",
              "pinyin": "weihai",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121400,
              "name": "枣庄",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zaozhuang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121500,
              "name": "日照",
              "tip": null,
              "firstChar": "r",
              "pinyin": "rizhao",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101121700,
              "name": "聊城",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liaocheng",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 15,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101130000,
      "name": "新疆",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101130100,
              "name": "乌鲁木齐",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wulumuqi",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130200,
              "name": "克拉玛依",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kelamayi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130300,
              "name": "昌吉回族自治州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changji",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130400,
              "name": "巴音郭楞蒙古自治州",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bayinguoleng",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130500,
              "name": "博尔塔拉蒙古自治州",
              "tip": null,
              "firstChar": "b",
              "pinyin": "boertala",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130600,
              "name": "伊犁哈萨克自治州",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yili",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130800,
              "name": "吐鲁番",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tulufan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101130900,
              "name": "哈密",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hami",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131000,
              "name": "阿克苏地区",
              "tip": null,
              "firstChar": "a",
              "pinyin": "akesu",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131100,
              "name": "克孜勒苏柯尔克孜自治州",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kezilesukeerkezi",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131200,
              "name": "喀什地区",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kashen",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131300,
              "name": "和田地区",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hetian",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131400,
              "name": "塔城地区",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tacheng",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131500,
              "name": "阿勒泰地区",
              "tip": null,
              "firstChar": "a",
              "pinyin": "aletai",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131600,
              "name": "石河子",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shihezi",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131700,
              "name": "阿拉尔",
              "tip": null,
              "firstChar": "a",
              "pinyin": "alaer",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131800,
              "name": "图木舒克",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tumushuke",
              "rank": 22,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101131900,
              "name": "五家渠",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wujiaqu",
              "rank": 23,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132000,
              "name": "铁门关",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tiemenguan",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132100,
              "name": "北屯市",
              "tip": null,
              "firstChar": "b",
              "pinyin": "beitunshi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132200,
              "name": "可克达拉市",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kekedalashi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132300,
              "name": "昆玉市",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kunyushi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132400,
              "name": "双河市",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shuangheshi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101132500,
              "name": "新星市",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinxingshi",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 31,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101140000,
      "name": "西藏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101140100,
              "name": "拉萨",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lasa",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140200,
              "name": "日喀则",
              "tip": null,
              "firstChar": "r",
              "pinyin": "rikaze",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140300,
              "name": "昌都",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changdou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140400,
              "name": "林芝",
              "tip": null,
              "firstChar": "l",
              "pinyin": "linzhi",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140500,
              "name": "山南",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shannan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140600,
              "name": "那曲",
              "tip": null,
              "firstChar": "n",
              "pinyin": "neiqu",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101140700,
              "name": "阿里地区",
              "tip": null,
              "firstChar": "a",
              "pinyin": "ali",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 26,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101150000,
      "name": "青海",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101150100,
              "name": "西宁",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xining",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150200,
              "name": "海东",
              "tip": null,
              "firstChar": "h",
              "pinyin": "haidong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150300,
              "name": "海北藏族自治州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "haibei",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150400,
              "name": "黄南藏族自治州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huangnan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150500,
              "name": "海南藏族自治州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hainan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150600,
              "name": "果洛藏族自治州",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guoluo",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150700,
              "name": "玉树藏族自治州",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yushu",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101150800,
              "name": "海西蒙古族藏族自治州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "haixi",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "q",
      "pinyin": null,
      "rank": 29,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101160000,
      "name": "甘肃",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101160100,
              "name": "兰州",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lanzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160200,
              "name": "定西",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dingxi",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160300,
              "name": "平凉",
              "tip": null,
              "firstChar": "p",
              "pinyin": "pingliang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160400,
              "name": "庆阳",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qingyang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160500,
              "name": "武威",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuwei",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160600,
              "name": "金昌",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jinchang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160700,
              "name": "张掖",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhangye",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160800,
              "name": "酒泉",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiuquan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101160900,
              "name": "天水",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tianshui",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161000,
              "name": "白银",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baiyin",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161100,
              "name": "陇南",
              "tip": null,
              "firstChar": "l",
              "pinyin": "longnan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161200,
              "name": "嘉峪关",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiayuguan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161300,
              "name": "临夏回族自治州",
              "tip": null,
              "firstChar": "l",
              "pinyin": "linxia",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101161400,
              "name": "甘南藏族自治州",
              "tip": null,
              "firstChar": "g",
              "pinyin": "gannan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 28,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101170000,
      "name": "宁夏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101170100,
              "name": "银川",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yinchuan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170200,
              "name": "石嘴山",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shizuishan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170300,
              "name": "吴忠",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuzhong",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170400,
              "name": "固原",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guyuan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101170500,
              "name": "中卫",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhongwei",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "n",
      "pinyin": null,
      "rank": 30,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101180000,
      "name": "河南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101180100,
              "name": "郑州",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhengzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180200,
              "name": "安阳",
              "tip": null,
              "firstChar": "a",
              "pinyin": "anyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180300,
              "name": "新乡",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinxiang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180400,
              "name": "许昌",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xuchang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180500,
              "name": "平顶山",
              "tip": null,
              "firstChar": "p",
              "pinyin": "pingdingshan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180600,
              "name": "信阳",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinyang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180700,
              "name": "南阳",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanyang",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180800,
              "name": "开封",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kaifeng",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101180900,
              "name": "洛阳",
              "tip": null,
              "firstChar": "l",
              "pinyin": "luoyang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181000,
              "name": "商丘",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shangqiu",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181100,
              "name": "焦作",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiaozuo",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181200,
              "name": "鹤壁",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hebi",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181300,
              "name": "濮阳",
              "tip": null,
              "firstChar": "p",
              "pinyin": "puyang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181400,
              "name": "周口",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhoukou",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181500,
              "name": "漯河",
              "tip": null,
              "firstChar": "l",
              "pinyin": "luohe",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181600,
              "name": "驻马店",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhumadian",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181700,
              "name": "三门峡",
              "tip": null,
              "firstChar": "s",
              "pinyin": "sanmenxia",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101181800,
              "name": "济源",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiyuan",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 16,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101190000,
      "name": "江苏",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101190100,
              "name": "南京",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanjing",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190200,
              "name": "无锡",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuxi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190300,
              "name": "镇江",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhenjiang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190400,
              "name": "苏州",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190500,
              "name": "南通",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nantong",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190600,
              "name": "扬州",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yangzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190700,
              "name": "盐城",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yancheng",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190800,
              "name": "徐州",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xuzhou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101190900,
              "name": "淮安",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huaian",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191000,
              "name": "连云港",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lianyungang",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191100,
              "name": "常州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191200,
              "name": "泰州",
              "tip": null,
              "firstChar": "t",
              "pinyin": "taizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101191300,
              "name": "宿迁",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suqian",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 10,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101200000,
      "name": "湖北",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101200100,
              "name": "武汉",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuhan",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200200,
              "name": "襄阳",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiangyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200300,
              "name": "鄂州",
              "tip": null,
              "firstChar": "e",
              "pinyin": "ezhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200400,
              "name": "孝感",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiaogan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200500,
              "name": "黄冈",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huanggang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200600,
              "name": "黄石",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huangshi",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200700,
              "name": "咸宁",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xianning",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200800,
              "name": "荆州",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jingzhou",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101200900,
              "name": "宜昌",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yichang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201000,
              "name": "十堰",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shiyan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201100,
              "name": "随州",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201200,
              "name": "荆门",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jingmen",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201300,
              "name": "恩施土家族苗族自治州",
              "tip": null,
              "firstChar": "e",
              "pinyin": "enshi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201400,
              "name": "仙桃",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiantao",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201500,
              "name": "潜江",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qianjiang",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201600,
              "name": "天门",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tianmen",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101201700,
              "name": "神农架",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shennongjia",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 17,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101210000,
      "name": "浙江",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101210100,
              "name": "杭州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hangzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210200,
              "name": "湖州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210300,
              "name": "嘉兴",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiaxing",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210400,
              "name": "宁波",
              "tip": null,
              "firstChar": "n",
              "pinyin": "ningbo",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210500,
              "name": "绍兴",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shaoxing",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210600,
              "name": "台州",
              "tip": null,
              "firstChar": "t",
              "pinyin": "taizhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210700,
              "name": "温州",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wenzhou",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210800,
              "name": "丽水",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lishui",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101210900,
              "name": "金华",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jinhua",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101211000,
              "name": "衢州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "quzhou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101211100,
              "name": "舟山",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhoushan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "z",
      "pinyin": null,
      "rank": 11,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101220000,
      "name": "安徽",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101220100,
              "name": "合肥",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hefei",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220200,
              "name": "蚌埠",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bangbu",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220300,
              "name": "芜湖",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuhu",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220400,
              "name": "淮南",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huainan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220500,
              "name": "马鞍山",
              "tip": null,
              "firstChar": "m",
              "pinyin": "maanshan",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220600,
              "name": "安庆",
              "tip": null,
              "firstChar": "a",
              "pinyin": "anqing",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220700,
              "name": "宿州",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suzhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220800,
              "name": "阜阳",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fuyang",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101220900,
              "name": "亳州",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bozhou",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221000,
              "name": "滁州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chuzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221100,
              "name": "淮北",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huaibei",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221200,
              "name": "铜陵",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tongling",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221300,
              "name": "宣城",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xuancheng",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221400,
              "name": "六安",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liuan",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221500,
              "name": "池州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chizhou",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101221600,
              "name": "黄山",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huangshan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "a",
      "pinyin": null,
      "rank": 12,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101230000,
      "name": "福建",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101230100,
              "name": "福州",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fuzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230200,
              "name": "厦门",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiamen",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230300,
              "name": "宁德",
              "tip": null,
              "firstChar": "n",
              "pinyin": "ningde",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230400,
              "name": "莆田",
              "tip": null,
              "firstChar": "p",
              "pinyin": "putian",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230500,
              "name": "泉州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "quanzhou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230600,
              "name": "漳州",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhangzhou",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230700,
              "name": "龙岩",
              "tip": null,
              "firstChar": "l",
              "pinyin": "longyan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230800,
              "name": "三明",
              "tip": null,
              "firstChar": "s",
              "pinyin": "sanming",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101230900,
              "name": "南平",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanping",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "f",
      "pinyin": null,
      "rank": 13,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101240000,
      "name": "江西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101240100,
              "name": "南昌",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanchang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240200,
              "name": "九江",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiujiang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240300,
              "name": "上饶",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shangrao",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240400,
              "name": "抚州",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fuzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240500,
              "name": "宜春",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yichun",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240600,
              "name": "吉安",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jian",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240700,
              "name": "赣州",
              "tip": null,
              "firstChar": "g",
              "pinyin": "ganzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240800,
              "name": "景德镇",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jingdezhen",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101240900,
              "name": "萍乡",
              "tip": null,
              "firstChar": "p",
              "pinyin": "pingxiang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101241000,
              "name": "新余",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xinyu",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101241100,
              "name": "鹰潭",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yingtan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "j",
      "pinyin": null,
      "rank": 14,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101250000,
      "name": "湖南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101250100,
              "name": "长沙",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changsha",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250200,
              "name": "湘潭",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiangtan",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250300,
              "name": "株洲",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhuzhou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250400,
              "name": "衡阳",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hengyang",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250500,
              "name": "郴州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chenzhou",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250600,
              "name": "常德",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changde",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250700,
              "name": "益阳",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yiyang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250800,
              "name": "娄底",
              "tip": null,
              "firstChar": "l",
              "pinyin": "loudi",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101250900,
              "name": "邵阳",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shaoyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251000,
              "name": "岳阳",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yueyang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251100,
              "name": "张家界",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhangjiajie",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251200,
              "name": "怀化",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huaihua",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251300,
              "name": "永州",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yongzhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101251400,
              "name": "湘西土家族苗族自治州",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xiangxi",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 18,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101260000,
      "name": "贵州",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101260100,
              "name": "贵阳",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guiyang",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260200,
              "name": "遵义",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zunyi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260300,
              "name": "安顺",
              "tip": null,
              "firstChar": "a",
              "pinyin": "anshun",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260400,
              "name": "铜仁",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tongren",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260500,
              "name": "毕节",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bijie",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260600,
              "name": "六盘水",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liupanshui",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260700,
              "name": "黔东南苗族侗族自治州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qiandongnan",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260800,
              "name": "黔南布依族苗族自治州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qiannan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101260900,
              "name": "黔西南布依族苗族自治州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qianxinan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 24,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101270000,
      "name": "四川",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101270100,
              "name": "成都",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chengdu",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270200,
              "name": "攀枝花",
              "tip": null,
              "firstChar": "p",
              "pinyin": "panzhihua",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270300,
              "name": "自贡",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zigong",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270400,
              "name": "绵阳",
              "tip": null,
              "firstChar": "m",
              "pinyin": "mianyang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270500,
              "name": "南充",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanchong",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270600,
              "name": "达州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dazhou",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270700,
              "name": "遂宁",
              "tip": null,
              "firstChar": "s",
              "pinyin": "suining",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270800,
              "name": "广安",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guangan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101270900,
              "name": "巴中",
              "tip": null,
              "firstChar": "b",
              "pinyin": "bazhong",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271000,
              "name": "泸州",
              "tip": null,
              "firstChar": "l",
              "pinyin": "luzhou",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271100,
              "name": "宜宾",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yibin",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271200,
              "name": "内江",
              "tip": null,
              "firstChar": "n",
              "pinyin": "neijiang",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271300,
              "name": "资阳",
              "tip": null,
              "firstChar": "z",
              "pinyin": "ziyang",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271400,
              "name": "乐山",
              "tip": null,
              "firstChar": "l",
              "pinyin": "leshan",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271500,
              "name": "眉山",
              "tip": null,
              "firstChar": "m",
              "pinyin": "meishan",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271600,
              "name": "雅安",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yaan",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271700,
              "name": "德阳",
              "tip": null,
              "firstChar": "d",
              "pinyin": "deyang",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271800,
              "name": "广元",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guangyuan",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101271900,
              "name": "阿坝藏族羌族自治州",
              "tip": null,
              "firstChar": "a",
              "pinyin": "aba",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101272000,
              "name": "凉山彝族自治州",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liangshan",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101272100,
              "name": "甘孜藏族自治州",
              "tip": null,
              "firstChar": "g",
              "pinyin": "ganzi",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "s",
      "pinyin": null,
      "rank": 23,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101280000,
      "name": "广东",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101280100,
              "name": "广州",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guangzhou",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280200,
              "name": "韶关",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shaoguan",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280300,
              "name": "惠州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "huizhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280400,
              "name": "梅州",
              "tip": null,
              "firstChar": "m",
              "pinyin": "meizhou",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280500,
              "name": "汕头",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shantou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280600,
              "name": "深圳",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shenzhen",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280700,
              "name": "珠海",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhuhai",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280800,
              "name": "佛山",
              "tip": null,
              "firstChar": "f",
              "pinyin": "foshan",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101280900,
              "name": "肇庆",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhaoqing",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281000,
              "name": "湛江",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhanjiang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281100,
              "name": "江门",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jiangmen",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281200,
              "name": "河源",
              "tip": null,
              "firstChar": "h",
              "pinyin": "heyuan",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281300,
              "name": "清远",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qingyuan",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281400,
              "name": "云浮",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yunfu",
              "rank": 21,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281500,
              "name": "潮州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chaozhou",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281600,
              "name": "东莞",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dongguan",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281700,
              "name": "中山",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhongshan",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281800,
              "name": "阳江",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yangjiang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101281900,
              "name": "揭阳",
              "tip": null,
              "firstChar": "j",
              "pinyin": "jieyang",
              "rank": 20,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282000,
              "name": "茂名",
              "tip": null,
              "firstChar": "m",
              "pinyin": "maoming",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282100,
              "name": "汕尾",
              "tip": null,
              "firstChar": "s",
              "pinyin": "shanwei",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101282200,
              "name": "东沙群岛",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dongshaqundao",
              "rank": 22,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 19,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101290000,
      "name": "云南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101290100,
              "name": "昆明",
              "tip": null,
              "firstChar": "k",
              "pinyin": "kunming",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290200,
              "name": "曲靖",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qujing",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290300,
              "name": "保山",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baoshan",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290400,
              "name": "玉溪",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yuxi",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290500,
              "name": "普洱",
              "tip": null,
              "firstChar": "p",
              "pinyin": "puer",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290700,
              "name": "昭通",
              "tip": null,
              "firstChar": "z",
              "pinyin": "zhaotong",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290800,
              "name": "临沧",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lincang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101290900,
              "name": "丽江",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lijiang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291000,
              "name": "西双版纳傣族自治州",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xishuangbanna",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291100,
              "name": "文山壮族苗族自治州",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wenshan",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291200,
              "name": "红河哈尼族彝族自治州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "honghe",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291300,
              "name": "德宏傣族景颇族自治州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dehong",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291400,
              "name": "怒江傈僳族自治州",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nujiang",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291500,
              "name": "迪庆藏族自治州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "diqing",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291600,
              "name": "大理白族自治州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dali",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101291700,
              "name": "楚雄彝族自治州",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chuxiong",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "y",
      "pinyin": null,
      "rank": 25,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101300000,
      "name": "广西",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101300100,
              "name": "南宁",
              "tip": null,
              "firstChar": "n",
              "pinyin": "nanning",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300200,
              "name": "崇左",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chongzuo",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300300,
              "name": "柳州",
              "tip": null,
              "firstChar": "l",
              "pinyin": "liuzhou",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300400,
              "name": "来宾",
              "tip": null,
              "firstChar": "l",
              "pinyin": "laibin",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300500,
              "name": "桂林",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guilin",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300600,
              "name": "梧州",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuzhou",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300700,
              "name": "贺州",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hezhou",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300800,
              "name": "贵港",
              "tip": null,
              "firstChar": "g",
              "pinyin": "guigang",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101300900,
              "name": "玉林",
              "tip": null,
              "firstChar": "y",
              "pinyin": "yulin",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301000,
              "name": "百色",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baise",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301100,
              "name": "钦州",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qinzhou",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301200,
              "name": "河池",
              "tip": null,
              "firstChar": "h",
              "pinyin": "hechi",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301300,
              "name": "北海",
              "tip": null,
              "firstChar": "b",
              "pinyin": "beihai",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101301400,
              "name": "防城港",
              "tip": null,
              "firstChar": "f",
              "pinyin": "fangchenggang",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "g",
      "pinyin": null,
      "rank": 20,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101310000,
      "name": "海南",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101310100,
              "name": "海口",
              "tip": null,
              "firstChar": "h",
              "pinyin": "haikou",
              "rank": 5,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310200,
              "name": "三亚",
              "tip": null,
              "firstChar": "s",
              "pinyin": "sanya",
              "rank": 6,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310300,
              "name": "三沙",
              "tip": null,
              "firstChar": "s",
              "pinyin": "sansha",
              "rank": 7,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310400,
              "name": "儋州",
              "tip": null,
              "firstChar": "d",
              "pinyin": "danzhou",
              "rank": 8,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310500,
              "name": "五指山",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wuzhishan",
              "rank": 19,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310600,
              "name": "琼海",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qionghai",
              "rank": 14,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310700,
              "name": "文昌",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wenchang",
              "rank": 18,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310800,
              "name": "万宁",
              "tip": null,
              "firstChar": "w",
              "pinyin": "wanning",
              "rank": 17,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101310900,
              "name": "东方",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dongfang",
              "rank": 10,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311000,
              "name": "定安",
              "tip": null,
              "firstChar": "d",
              "pinyin": "dingan",
              "rank": 9,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311100,
              "name": "屯昌",
              "tip": null,
              "firstChar": "t",
              "pinyin": "tunchang",
              "rank": 16,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311200,
              "name": "澄迈",
              "tip": null,
              "firstChar": "c",
              "pinyin": "chengmai",
              "rank": 4,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311300,
              "name": "临高",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lingao",
              "rank": 12,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311400,
              "name": "白沙黎族自治县",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baisha",
              "rank": 1,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311500,
              "name": "昌江黎族自治县",
              "tip": null,
              "firstChar": "c",
              "pinyin": "changjiang",
              "rank": 3,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311600,
              "name": "乐东黎族自治县",
              "tip": null,
              "firstChar": "l",
              "pinyin": "ledong",
              "rank": 11,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311700,
              "name": "陵水黎族自治县",
              "tip": null,
              "firstChar": "l",
              "pinyin": "lingshui",
              "rank": 13,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311800,
              "name": "保亭黎族苗族自治县",
              "tip": null,
              "firstChar": "b",
              "pinyin": "baoting",
              "rank": 2,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          },
          {
              "code": 101311900,
              "name": "琼中黎族苗族自治县",
              "tip": null,
              "firstChar": "q",
              "pinyin": "qiongzhong",
              "rank": 15,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 0,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "h",
      "pinyin": null,
      "rank": 21,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101320000,
      "name": "香港",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101320300,
              "name": "香港",
              "tip": null,
              "firstChar": "x",
              "pinyin": "xianggang",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "x",
      "pinyin": null,
      "rank": 33,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101330000,
      "name": "澳门",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101330100,
              "name": "澳门",
              "tip": null,
              "firstChar": "a",
              "pinyin": "aomen",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "a",
      "pinyin": null,
      "rank": 34,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  },
  {
      "code": 101340000,
      "name": "台湾",
      "tip": null,
      "subLevelModelList": [
          {
              "code": 101341100,
              "name": "台湾",
              "tip": null,
              "firstChar": "t",
              "pinyin": "taiwan",
              "rank": 0,
              "mark": 0,
              "positionType": 0,
              "cityType": 0,
              "capital": 1,
              "color": null,
              "recruitmentType": null,
              "cityCode": null,
              "regionCode": 0
          }
      ],
      "firstChar": "t",
      "pinyin": null,
      "rank": 32,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": null,
      "regionCode": 0
  }
]