export const  hotCityList = [
  {
      "code": 100010000,
      "name": "全国",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "",
      "regionCode": 0
  },
  {
      "code": 101010100,
      "name": "北京",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "010",
      "regionCode": 0
  },
  {
      "code": 101020100,
      "name": "上海",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "021",
      "regionCode": 0
  },
  {
      "code": 101280100,
      "name": "广州",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "020",
      "regionCode": 0
  },
  {
      "code": 101280600,
      "name": "深圳",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0755",
      "regionCode": 0
  },
  {
      "code": 101210100,
      "name": "杭州",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0571",
      "regionCode": 0
  },
  {
      "code": 101030100,
      "name": "天津",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "022",
      "regionCode": 0
  },
  {
      "code": 101110100,
      "name": "西安",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "029",
      "regionCode": 0
  },
  {
      "code": 101190400,
      "name": "苏州",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0512",
      "regionCode": 0
  },
  {
      "code": 101200100,
      "name": "武汉",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "027",
      "regionCode": 0
  },
  {
      "code": 101230200,
      "name": "厦门",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0592",
      "regionCode": 0
  },
  {
      "code": 101250100,
      "name": "长沙",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0731",
      "regionCode": 0
  },
  {
      "code": 101270100,
      "name": "成都",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "028",
      "regionCode": 0
  },
  {
      "code": 101180100,
      "name": "郑州",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "0371",
      "regionCode": 0
  },
  {
      "code": 101040100,
      "name": "重庆",
      "tip": null,
      "subLevelModelList": null,
      "firstChar": null,
      "pinyin": null,
      "rank": 0,
      "mark": 0,
      "positionType": 0,
      "cityType": 0,
      "capital": 0,
      "color": null,
      "recruitmentType": null,
      "cityCode": "023",
      "regionCode": 0
  }
]