<template>
  <a-config-provider :locale="locale">
    <router-view v-slot="{ Component }">
      <transition name="view">
        <component :is="Component"></component>
      </transition>
    </router-view>
  </a-config-provider>
  <!-- <router-view></router-view> -->
</template>

<script setup lang="ts">
import locale from "ant-design-vue/es/locale/zh_CN";
import { useJobTypeStore } from "@/store/positionType";
import { useHotSearchPosition } from "@/store/position";
import { useConsult } from "@/store/consult";
import { useCity } from "@/store/city";
import { useSwiperStore } from "@/store/swiper";
import { useIndustryStore } from "@/store/industry";
import { useConfig } from "@/store/config";
const { preventCity } = useCity();
const mangerToken = localStorage.getItem("mangerToken");
useJobTypeStore().getJobs(); //职位类型

useIndustryStore().getIndustry(); //行业
useCity().getCity();

useConfig().getConfigs(); // 网站配置

if (!mangerToken) {
  useSwiperStore().getSwiper(); //轮播
  useHotSearchPosition().getData(preventCity); //热招职位
  useHotSearchPosition().getSortOrRandomData(preventCity); //热招职位
  useConsult().getConsultCategory(); //资讯分类
}

const route = useRoute()
const router = useRouter()
onMounted(() => {
  // console.log(route,route.path?.split('/')[1]!=='company');
  console.log(route, router, router.currentRoute.value.fullPath, '/company/positionManage'.split('/'));
  
  window.addEventListener('resize', checkMobile);
})

// 监听是否是手机
const checkMobile = () => {
  if(window.innerWidth < 768){
    if(route.path?.split('/')[1]!=='company' && route.path?.split('/')[1]!=='h5') {
      router.replace('/h5')
    }
  } else {
    if (route.path?.split('/')[1]==='h5') {
      router.replace('/')
    }
  }
}
</script>

<style lang="less" scoped>
.view-enter-from,
.view-leave-to {
  opacity: 0;
}
.view-enter-to,
.view-leave-from {
  opacity: 1;
  transition: all 0.5s ease;
}
</style>
